import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_CLIENTS_LIST, FETCH_PROJECT_LIST, FETCH_RESOURCES_LIST, FETCH_TASk_LIST } from "../api";
import { Dayjs } from "dayjs";
import moment from "moment"

export const Fetch_Clients_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_CLIENTS_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createProjectsApi = async (
  project_name: string | null,
  currency: string | null,
  client_id: string | null,
  estimated_cost: String | null,
  actual_cost: string | null,
  start_date: string | null | Dayjs,
  end_date: string | null | Dayjs,
  estimated_hours: number | null,
  actual_hours: number | null,
  status: string | null,
  project_document: string[],
  project_type: string | null,
  description: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    project_name: project_name,
    currency: currency,
    client_id: client_id,
    estimated_cost: estimated_cost,
    actual_cost: actual_cost,
    start_date: start_date,
    end_date: end_date,
    estimated_hours: estimated_hours,
    actual_hours: actual_hours,
    status: status,
    project_document: project_document,
    project_type: project_type,
    description: description,
  };

  try {
    const response = await axios.post(`${base_api}/api/v1/project`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const createResourcesApi = async (
  project_resources: {
    user_id: number;
    project_id: number;
    employee_type: string;
  }[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    project_resources: project_resources,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/project-resources`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};


export const Fetch_Resources_List = (
  customer_id: string|undefined,
  searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_RESOURCES_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createTaskApi = async (
  // project_resources: {
  //   user_id: number;
  //   project_id: number;
  //   employee_type: string;
  // }[]
  task_name:string,
  project_id:string|undefined,
  estimated_hours:string,
  actual_hours:string,
  start_date:string | null | Dayjs,
  end_date:string | null | Dayjs,
  description:string,
  status:string,
  task_document:string[],
  task_resources:{
    user_id: number;
    project_id: number;
  }[]
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
   // project_resources: project_resources,
   task_name:task_name,
   project_id:project_id,
   estimated_hours:estimated_hours,
   actual_hours:actual_hours,
   start_date:start_date,
   end_date:end_date,
   description:description,
   status:status,
   task_document:task_document,
   task_resources:task_resources
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/task`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Task_List = (
  customer_id: string|undefined,
 // searchTerm: string,
  numOfItems: number,
  page: number
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  // if (searchTerm !== "") {
  //   query += `&search=${searchTerm}`;
  // }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  // if (startDate && endDate) {
  //   const start = moment(startDate).format("YYYY-MM-DD");
  //   const end = moment(endDate).format("YYYY-MM-DD");
  //   query += `&start_date=${start}&end_date=${end}`;
  // }

  return axios.get(`${FETCH_TASk_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const fetchEditTask = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/task/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};


export const updateTask = async (
  id: string,
  task_name:string,
  project_id:string|undefined,
  estimated_hours:string,
  actual_hours:string,
  start_date:string | null | Dayjs,
  end_date:string | null | Dayjs,
  description:string,
  status:string,
  task_document:string[],
  task_resources:{
    user_id: number;
    project_id: number;
  }[]
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    task_name:task_name,
    project_id:project_id,
    estimated_hours:estimated_hours,
    actual_hours:actual_hours,
    start_date:start_date,
    end_date:end_date,
    description:description,
    status:status,
    task_document:task_document,
    task_resources:task_resources
  };

  try {
    const response = await axios.put(
      `${base_api}/api/v1/task/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Project_List = (
 // customer_id: string|undefined,
  searchTerm: string,
  numOfItems: number,
  page: number,
  startDate: Date | null,
  endDate: Date | null

) => {
  const access_token = localStorage.getItem("access_token");
   let query = `?size=${numOfItems}&page=${page}`;
  //let query = `?project_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  // if (group_id) {
  //   query += `&group_id=${group_id}`;
  // }

  // if (department_id) {
  //   query += `&department_id=${department_id}`;
  // }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${FETCH_PROJECT_LIST}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};


export const fetchEditProject = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/project/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateProject = async (
  id: string,
  project_name: string | null,
  currency: string | null,
  client_id: string | null,
  estimated_cost: String | null,
  actual_cost: string | null,
  start_date: string | null | Dayjs,
  end_date: string | null | Dayjs,
  estimated_hours: number | null,
  actual_hours: number | null,
  status: string | null,
  project_document: string[],
  project_type: string | null,
  description: string | null
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    project_name: project_name,
    currency: currency,
    client_id: client_id,
    estimated_cost: estimated_cost,
    actual_cost: actual_cost,
    start_date: start_date,
    end_date: end_date,
    estimated_hours: estimated_hours,
    actual_hours: actual_hours,
    status: status,
    project_document: project_document,
    project_type: project_type,
    description: description,
  };

  try {
    const response = await axios.put(
      `${base_api}/api/v1/project/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Edit_Resources_List_List = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${FETCH_RESOURCES_LIST}`);
  return fetch(
    `${FETCH_RESOURCES_LIST}`,
    {
      method: "GET",
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    }
  );
};

export const updateResources = async (
  id: string,
  billing_cost:number,
  actual_cost:number
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    billing_cost:billing_cost,
    actual_cost:actual_cost
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/project-resources/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};