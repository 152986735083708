import rightArrow from "../../assets/chevron-right.svg";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@material-tailwind/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import assign from "../../assets/images/assign.png";
import Footer from "../Layout/footer";
import { getCustomerResourseList } from "../../redux/actions/RoleManagement/RoleManagementActions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ROLE_MANAGEMENT,
  ROLE_MANAGEMENT_EDIT_ROLE,
  ROLE_MANAGEMENT_ROLE_DETAILS,
} from "../../utils/routeConstant";
import { fetchRoleDetails } from "../common/services/roleManagement";
import RenderResources from "../common/ResourceList/Resources";

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  //   width: "325px",
  width: "100%",
};
const menuStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
};

interface Group {
  resource_id: number;
  resource_name: string;
  parent_id: number | null;
  children?: Group[];
}

const flattenGroupData = (groupData: Group[]): any => {
  const flattenedRecords: any[] = [];

  function flattenGroup(group: any[]) {
    console.log(group)
    group.forEach((item: any) => {
      const record = {
        resource_id: item.resource_id,
        resource_name: item.resource_name,
        parent_id: item.parent_id,
        children: item?.children?.length
      };
      flattenedRecords.push(record);
      if (item.children && item.children.length > 0) {
        flattenGroup(item.children);
      }
    });
  }

  flattenGroup(groupData);

  return flattenedRecords;
};

const EditRoleDetails = () => {
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = useState(false);

  ////access boxes ///////////
  const [customerResourceList, setCustomerResourceList] = useState<any[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);

  useEffect(() => {
    // Fetch country based on latitude and longitude
    async function fetchResourceList() {
      try {
        setLoading(true);
        const data = await getCustomerResourseList();
        if (data) {
          setCustomerResourceList(data);

        } else {
          console.error("Failed to fetch customer resource list.");
        }
      } catch (error) {
        console.error("Error fetching customer resource list:", error);
      }
      setLoading(false);
    }

    fetchResourceList();
  }, []);

  //////////////////// get request ////////////////////

  const [roleName, setRoleName] = useState("");
  const [createdUpdatedBy, setCreatedUpdatedBy] = useState("");
  const [status, setStatus] = useState("");
  const [addedDate, setAddedDate] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [resourceList, setResource_list] = useState<number[]>([]);

  const loginData = useSelector((state: any) => state.auth.login.login_details);

  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
  }

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    console.log("id", id);
    setLoading(true);
    fetchRoleDetails(id)
      .then((response: Response) => response.json())
      .then((data: any) => {
        if (data.result) {
          console.log("data.result.status", data.result.status);
          setRoleName(data.result.role_name);
          if (data.result.created_by) {
            setCreatedUpdatedBy(
              data.result.created_by.firstName +
                " " +
                data.result.created_by.lastName
            );
          }
          setStatus(data.result.status);
          setAddedDate(data.result.createdAt);
          setLastUpdatedDate(data.result.updatedAt);
          // setResource_list(
          //   data.result.UserRolePrivileges.map(
          //     (privilege: any) => privilege.resource_id
          //   )
          // );
          setResource_list(flattenGroupData(data?.result?.resources)?.map((item: any) => item.resource_id));
          console.log("resourceList", resourceList);
        }
      })
      .catch((error: Error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  ////////////////////////////////////////////////////////////

  const handleGroupChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  function dummyParentFunction(
    event: any,
    id: any,
    parent_id: any,
    temp: any[]
  ) {}

  function dummyChildrenFunction(event: any, id: any, parent_id: any) {}

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <div className="flex-grow">
          <div className="bg-gray-100 pt-10 px-[18.5px] h-full">
            <div className="flex flex-row items-center h-12 justify-between">
              <div className="flex flex-row gap-[10px] justify-center items-start">
                <button onClick={() => navigate("/role-management")}>
                  <img
                    src={rightArrow}
                    alt="right arrow sign"
                    className="h-6 w-6 shrink-0 bg-black rounded-full"
                  />
                </button>
                <div className="flex flex-col">
                  <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                    Role Management
                  </span>
                  <span className="text-black font-[Poppins] text-base font-semibold leading-normal">
                    Edit Role Details
                  </span>
                </div>
              </div>
              <div className="flex justify-center items-center gap-[7px]">
                <button
                  onClick={() =>
                    navigate(`${ROLE_MANAGEMENT_ROLE_DETAILS}`, {
                      state: { id: id },
                    })
                  }
                  className="flex w-[124px] py-[2px] px-[2px] justify-center items-center rounded-md h-9 border borderButton"
                >
                  <span className="text font-[Poppins] text-xs leading-[20px]">
                    View User List
                  </span>
                </button>
                <button
                  onClick={() =>
                    navigate("/edit-role", {
                      state: { id: id },
                    })
                  }
                  className="flex w-[120px] py-[2px] px-[2px] justify-center items-center rounded-md bg-[#5D42D1] h-9"
                >
                  <div className="flex justify-center items-center gap-[7px]">
                    <img
                      src={assign}
                      alt="right arrow sign"
                      className="h-4 w-4 shrink-0 relative"
                    />
                    <span className="text-white font-[Poppins] text-xs leading-[20px]">
                      Edit Role
                    </span>
                  </div>
                </button>
              </div>
            </div>

            <div className="w-full mt-5">
              <div className=" py-[25px] flex flex-col flex-start gap-8 shrink-0 rounded-[9.601px] shadow-lg bg-white">
                <Container component="main">
                  <Box sx={{ paddingBottom: "32px" }}>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: "0px" }}
                    >
                      <div className="mt-8 text-[#34254] font-[Poppins] text-base font-medium leading-4 mb-[30px]">
                        Role Details
                      </div>
                      <Grid
                        container
                        style={{
                          gap: "20px",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                          alignSelf: "stretch",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              autoComplete="given-name"
                              name="roleName"
                              id="roleName"
                              label="Role Name"
                              placeholder="Enter role name"
                              required
                              value={roleName}
                              InputLabelProps={{
                                shrink: true,
                                sx: labelStyle,
                              }}
                              InputProps={{
                                sx: {
                                  ...inputStyle,
                                  "& input:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                    WebkitTextFillColor: "inherit",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              autoComplete="given-name"
                              name="created/updatedBy"
                              id="created/updatedBy"
                              label="Created By/ Updated By"
                              required
                              value={createdUpdatedBy}
                              InputLabelProps={{
                                shrink: true,
                                sx: labelStyle,
                              }}
                              InputProps={{
                                sx: {
                                  ...inputStyle,
                                  "& input:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                    WebkitTextFillColor: "inherit",
                                  },
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel
                                id="demo-simple-select-label"
                                shrink
                                sx={labelStyle}
                              >
                                Status
                              </InputLabel>

                              <Select
                                key={status}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                sx={inputStyle}
                                value={status}
                                onChange={handleGroupChange}
                                displayEmpty
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Status"
                                  />
                                }
                                MenuProps={{
                                  PaperProps: {
                                    style: menuStyle,
                                  },
                                }}
                              >
                                <MenuItem
                                  value={"active"}
                                  style={menuStyle}
                                  disabled
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                      alignItems: "center",
                                    }}
                                  >
                                    Active
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="11"
                                      viewBox="0 0 11 11"
                                      fill="#00CBA0"
                                    >
                                      <circle cx="5.5" cy="5.35156" r="5" />
                                    </svg>
                                  </div>
                                </MenuItem>
                                <MenuItem
                                  value={"archived"}
                                  style={menuStyle}
                                  disabled
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                      alignItems: "center",
                                    }}
                                  >
                                    Archived
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="11"
                                      viewBox="0 0 11 11"
                                      fill="#FA1464"
                                    >
                                      <circle cx="5.5" cy="5.35156" r="5" />
                                    </svg>
                                  </div>
                                </MenuItem>
                                <MenuItem
                                  value={"inactive"}
                                  style={menuStyle}
                                  disabled
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "80%",
                                      alignItems: "center",
                                    }}
                                  >
                                    In-active
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11"
                                      height="11"
                                      viewBox="0 0 11 11"
                                      fill="#FF9950"
                                    >
                                      <circle cx="5.5" cy="5.35156" r="5" />
                                    </svg>
                                  </div>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            autoComplete="given-name"
                            name="addedDate"
                            id="addedDate"
                            label="Added Date"
                            required
                            value={
                              addedDate && !isNaN(new Date(addedDate).valueOf())
                                ? new Date(addedDate)
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                              sx: labelStyle,
                            }}
                            InputProps={{
                              sx: {
                                ...inputStyle,
                                "& input:-webkit-autofill": {
                                  WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                  WebkitTextFillColor: "inherit",
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            autoComplete="given-name"
                            name="lastUpdatedDate"
                            id="lastUpdatedDate"
                            label="Last Updated Date"
                            required
                            value={
                              lastUpdatedDate &&
                              !isNaN(new Date(lastUpdatedDate).valueOf())
                                ? new Date(lastUpdatedDate)
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            InputLabelProps={{
                              shrink: true,
                              sx: labelStyle,
                            }}
                            InputProps={{
                              sx: {
                                ...inputStyle,
                                "& input:-webkit-autofill": {
                                  WebkitBoxShadow: "0 0 0 1000px #fff inset",
                                  WebkitTextFillColor: "inherit",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>

                      <div className="text-[#34254] font-[Poppins] text-base font-medium leading-4 mt-[30px] mb-[41px]">
                        Accesses given to User
                      </div>
                      {/* <Grid
                        container
                        style={{
                          gap: "48px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          alignSelf: "stretch",
                          marginBottom: "60px",
                        }}
                      >
                        {customerResourceList.map((obj: any) => {
                          if (!checkRepeat.has(obj.resource_id)) {
                            const subResources = customerResourceList
                              .filter(
                                (idx: any) =>
                                  idx.parent_id === obj.resource_id
                              )
                              .map((subObj: any) => {
                                checkRepeat.add(subObj.resource_id); // Add sub resource_id to the set
                                return (
                                  <li
                                    className="w-[366px] flex flex-row items-start"
                                    key={subObj.resource_id}
                                  >
                                    <Checkbox
                                      ripple={false}
                                      crossOrigin=""
                                      value="insights"
                                      id={"insights"}
                                      containerProps={{
                                        className: "p-0",
                                      }}
                                      // onClick={(e) => {handlePrompts(e, item.id); autoDeselect(e,item.id)}}
                                      checked={resourceList.includes(
                                        obj.resource_id
                                      )} // Check if the resource_id of the current item is in the resourceList array
                                      className="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                    />
                                    <label
                                      htmlFor={subObj.resource_id}
                                      className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal pl-3 mt-[-3px]"
                                    >
                                      {subObj.resource_name}
                                    </label>
                                  </li>
                                );
                              });
                            return (
                              <div
                                key={obj.resource_id}
                                className="gap-7 flex flex-row w-full"
                              >
                                <div className="flex flex-row gap-3 w-[28%] items-center h-3">
                                  <Checkbox
                                    ripple={false}
                                    crossOrigin=""
                                    value="insights"
                                    id={"insights"}
                                    containerProps={{
                                      className: "p-0",
                                    }}
                                    // onClick={(e) => {handlePrompts(e, item.id); autoDeselect(e,item.id)}}
                                    checked={resourceList.includes(
                                      obj.resource_id
                                    )} // Check if the resource_id of the current item is in the resourceList array
                                    className="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:scale-105 hover:before:opacity-0" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                  />
                                  <label
                                    htmlFor={obj.resource_name}
                                    className="inline-flex text-[#797979] font-[Poppins] text-sm font-semibold leading-normal"
                                  >
                                    {obj.resource_name}
                                  </label>
                                </div>
                                <div className="flex flex-row gap-3 items-start">
                                  <ul className="grid grid-cols-2 gap-5">
                                    {subResources}
                                  </ul>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Grid> */}

                      <RenderResources
                        renderData={customerResourceList}
                        isChecked={false}
                        cssClass="h-4 w-4 rounded-md shrink-0 border-gray-900/20 checked:bg-[#FCAB10] checked:border-[#FCAB10] transition-all hover:before:opacity-0"
                        resourceList={resourceList}
                        handleResourceListChildren={dummyChildrenFunction}
                        handleResourceListParent={dummyParentFunction}
                      />
                    </Box>
                  </Box>
                </Container>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {loading && (
        <div className="bg-black opacity-70 fixed top-0 left-0 h-screen w-screen">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
            role="status"
          >
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRoleDetails;
