import React, { useState } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

interface MyStatefulEditorProps {
  onChange?: (value: string) => void;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({ onChange }) => {
  const [value, setValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());

  const handleChange = (value: EditorValue) => {
    setValue(value);
    if (onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      onChange(value.toString('html'));
    }
  };

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
      className='h-[300px]'
    />
  );
};

export default MyStatefulEditor;





