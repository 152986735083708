import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEditEmployee } from "../common/services/Employee";
import moment from "moment";
import { log } from "console";

interface EmployeeData {
  empCode: string;
  empId: string;
  empName: string;
  email: string;
  number: string;
  business_unit: string;
  role: string;
  department: string;
  designation: string;
  team_manager: string;
  reporting_manager: string;
  status: string;
  MoE: string;
  YOE: string;
  DOB: string;
  DOJ: string;
}

const ViewEmployeeList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    empCode: "",
    empId: "",
    empName: "",
    email: "",
    number: "",
    business_unit: "",
    role: "",
    department: "",
    designation: "",
    team_manager: "",
    reporting_manager: "",
    status: "",
    MoE: "",
    YOE: "",
    DOB: "",
    DOJ: "",
  });
  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditEmployee(id)
      .then((data) => {
        console.log(data.result, "data--res----edit--->>>>");
        if (data.result) {
          // setUpdateData(data);
          console.log("data.result.status", data.result?.Group?.name);
          setEmployeeData({
            empCode: data.result?.employee_code || "",
            empId: data.result?.employee_id,
            empName: data.result?.firstName,
            email: data.result?.email || "",
            number: data.result?.phone || "",
            business_unit: data.result?.Group?.name,
            role: data.result?.Role?.role_name,
            department: data.result?.Department?.name,
            designation: data.result?.Designation?.name,
            team_manager: data.result?.team_manager?.firstName,
            reporting_manager: data?.result?.reporting_manager?.firstName,
            status: data.result?.status,
            MoE: data.result?.mode_of_employment,
            YOE: data.result?.experience,
            DOB: data.result?.date_of_birth,
            DOJ: data.result?.date_of_joining,
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  console.log("101", employeeData);

  return (
    <>
      <div className="bg-[#FBFBFB] font-[Poppins] px-4 pt-6 pb-12">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/employee-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Employee Details
            </span>
          </div>
        </div>
        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-[Poppins] font-semibold">Details</h2>
          <div className="py-4">
            <div className="px-2.5 py-5 rounded-md viewBackground">
              <div className="flex flex-col gap-[15px]">
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Code
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.empCode || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee ID
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.empId || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Name
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.empName || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Email
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.email || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Phone Number
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.number || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Role
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.role || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Business Unit
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.business_unit || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Department
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.department || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Designation
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.designation || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Team Manager
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.team_manager || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Reprting Manager
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.reporting_manager || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Emplpoyment Status
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.status || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Mode of Employment
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.MoE || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Years of Experience
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.YOE || "--"}
                    </span>
                  </div>
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Date of Birth
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.DOB || "--"}
                    </span>
                  </div>
                </div>
                <hr className="h-[2px] bg-[#EDEDED]" />
                <div className="grid grid-cols-3">
                  <div className="flex flex-col justify-between h-[45px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Date of Joining
                    </span>
                    <span className="font-[Inter] font-medium text-[14px] leading-[16.94px] text-[#1D1A22]">
                      {employeeData?.DOJ || "--"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEmployeeList;
