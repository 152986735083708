import React, { useEffect, useState, ChangeEvent, useCallback } from "react";
import search from "../../assets/Search.svg";
import profile from "../../assets/Ellipse 580.png";
import eye from "../../assets/Crud_Icons/view.svg";
import filter from "../../assets/filter.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import SelectDropdown from "../EmployeeManagement/SelectDropDown";
import { useNavigate } from "react-router-dom";
import TablePaginationDemo from "../common/Pagenation";
import { Fetch_Policy_List } from "../common/services/companyManagement";
import { useSelector } from "react-redux";
import Filter from "../Filter/Filter";
import Search from "../SearchBar/Search";
import { Chip } from "@material-ui/core";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Leave_Type {
  id: string;
  name: string;
  recurrence: string;
  renewal_date: string;
}

export const Policies = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  let customer_id = 0;
  let access_token = "";

  const dispatch = useDispatch();

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Policy_List(
        customer_id,
        searchTerm,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id)
      );

      if (response.status === 200) {
        console.log("Data: company policy Tpye", response.data.result?.rows);
        setTableData(response?.data?.result?.rows);

        setCount(response.data?.result?.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [
    searchTerm,
    numOfItems,
    page,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
  ]);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  useEffect(() => {
    console.log("chek print");

    const fetchHistory = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );
        console.log(historyData, "historyData---->>>");

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    console.log("chek print");

    const fetchDepartment = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );
        console.log(historyData, "historyData department---->>>");

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  const handleFilterApply = () => {
    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchLeaveType();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchLeaveType();
  };

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedBusinessUnit(option);
    console.log("Selected Business Unit:", option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedDepartmentUnit(option);
    console.log("Selected Business Unit:", option);
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCalanderClick = () => {};
  const handleDateChange = () => {};

  return (
    <div className="pt-[50px] flex flex-col gap-2">
      <div className="h-[40px] w-full">
        <div className="h-full flex justify-between items-center">
          <Search onChange={updateSearchTerm} label="Search with Policy Name" />
          <div className="h-full flex items-center gap-5">
            <div className="h-full flex items-center gap-2">
              {departmentSelect && (
                <Chip
                  label={selectedDepartmentUnit?.name}
                  onDelete={handleDeleteDepartment}
                  size="small"
                />
              )}
              {businessSelect && (
                <Chip
                  label={selectedBusinessUnit?.name}
                  onDelete={handleDeleteBusiness}
                  size="small"
                />
              )}
            </div>
            <button
              className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
              onClick={() => setIsOpen(true)}
            >
              <img src={filter} alt="filter" className="h-3.5 w-3.5" />
              <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                Filter
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-b-[0.6px] border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Policy name
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Category
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Recurrence
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Renewal Date
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Workflow Stage
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Department
              </th>
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item) => (
              <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {item?.name}
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  HR
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {item?.recurrence}
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {item?.renewal_date}
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  Policy in Use
                </td>
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  IT
                </td>
                <td className="pl-6 pr-2.5">
                  <div className="flex items-center gap-2">
                    <img
                      src={eye}
                      onClick={() =>
                        navigate("/view-policy-details", {
                          state: { id: item.id },
                        })
                      }
                      alt="View"
                      className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                    />
                    <img
                      onClick={() =>
                        navigate(`/edit-policy-details`, {
                          state: { id: item.id },
                        })
                      }
                      src={edit}
                      alt="Edit"
                      className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate={false}
        isOther
        heading="Policy Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};
