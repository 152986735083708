import React, { useCallback, useEffect, useState } from "react";
import search from "../../assets/Search.svg";
import edit from "../../assets/Crud_Icons/edit.svg";
import { useNavigate } from "react-router-dom";
import del from "../../assets/Crud_Icons/delete.svg";
import { Fetch_Expense_Type_List } from "../common/services/ExpenseType";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import TablePaginationDemo from "../common/Pagenation";
import Search from "../SearchBar/Search";

interface Leave_Type {
  id: string;
  expense_category_name: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Expense Type
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white px-12 py-2 rounded mr-2"
          >
            Yes Sure
          </button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-12 py-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const Clients = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectLeaveType, setSelectLeaveType] = useState<Leave_Type[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Leave_Type | null>(null);
  const [tableData, setTableData] = useState<Leave_Type[]>();

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  const handleDeleteClick = (item: Leave_Type) => {
    console.log(item, "delete value");
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/expenses-category/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Expense_Type_List(
        customer_id,
        searchTerm,
        numOfItems,
        page
      );

      if (response.status === 200) {
        console.log("Data: Leave Tpye", response.data.result);
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [page, searchTerm, numOfItems]);

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  return (
    <>
      <div className="mt-6 flex justify-between">
        <Search onChange={updateSearchTerm} label="Search with Employee Name" />
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                Expense Type
              </th>
              <th className="flex justify-end pr-6 py-2.5 text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item) => (
              <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                <td className="text-[#3F4254] font-[500] text-[12px] pl-6 pr-2.5 py-1">
                  {item?.expense_category_name}
                </td>

                <td className="pl-2.5 pr-6 py-1">
                  <div className="flex justify-end items-center gap-2">
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={edit}
                      onClick={() =>
                        navigate(`/edit-client/${item.id}`, {
                          state: { id: item.id },
                        })
                      }
                      alt="Edit"
                    />
                    <img
                      className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      src={del}
                      alt="Delete"
                      onClick={() => handleDeleteClick(item)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showModal && (
          <DeleteConfirmationModal
            onDelete={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        )}
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
    </>
  );
};

export default Clients;
