import React, { useState } from "react";
import rightArrow from "../../assets/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import TextField from "@mui/material/TextField";
import { DesignationForm } from "../../utils/helperData";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import right from "../../image/chevron-right.png";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { createDesignation } from "../common/services/DesignationManagement";
import { useSelector } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const validationData: any = ["designation"];

const labelStyle = {
  color: "rgba(0, 0, 0, 0.60)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
};
const inputStyle = {
  color: "#464646",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  letterSpacing: "-0.165px",
  display: "flex",
  padding: "0px 12px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRadius: "10px",
  border: "var(--Our_Colors-Grays-400, #C5CDD4)",
  height: "56px",
  width: "325px",
};

export const AddDesignation = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [designation, setDesignation] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = React.useState<{ [key: string]: any }>({});
  console.log(designation, "designation-->>>");

  const loginData = useSelector((state: any) => state.auth.login.login_details);
  let customerId = 0;
  let access_token = "";
  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customerId = loginData.customer_id;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/designation-management");
  };
  const navigate = useNavigate();

  function handleValidation() {
    const updated: { [key: string]: any } = { ...isValid };

    for (let item of validationData) {
      updated[item] = updated[item] || false;
    }

    updated["designation"] = !designation;
    //updated["resources"] = !resourceList.length;

    setIsValid(updated);

    if (!designation) {
      return;
    }
  }

  function handleIsValidItem(item: string) {
    const updated = { ...isValid };

    updated[`${item}`] = false;

    setIsValid(updated);
  }

  const handleClick = async () => {
    handleValidation();
    await postData();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data, "submitdata--->>>>");

    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const postData = async () => {
    setLoading(true);
    try {
      const response = await createDesignation(designation, customerId);
      // console.log(response,"respost--->>>");

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/designation-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className="p-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Designation added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row px-[14px] items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/designation-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Designation Details
          </span>
        </div>

        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate(`/designation-management`)}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            onClick={handleClick}
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg buttoncss h-12"
          >
            <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Save
            </div>
          </button>
        </div>
      </div>

      {/* <div className="w-[100%] mt-6 p-4 rounded overflow-hidden shadow-lg bg-white m-4">
        <div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="p-4">
              <label className="text-[#262626] font-[500] text-[14px]">
                Designation Details
              </label>
            </div>
            <TextField
             onChange={(e) => {
              setDesignation(e.target.value);
            }}
              required
              id="outlined-required"
              label="Designation"
              defaultValue="Enter Designation"
            />
          </Box>
        </div>
      </div> */}

      <div className="w-full mt-5 ">
        <div className="py-[25px] flex flex-col flex-start gap-8 shrink-0 rounded overflow-hidden shadow-custom m-4">
          <div className="text-[#262626] font-[500] text-[14px] ml-6 font-['Poppins']">
            Designation details
          </div>
          <Container component="main">
            <Box sx={{ paddingBottom: "32px" }}>
              <Box
                component="form"
                className="flex flex-col gap-8"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: "0px" }}
              >
                <Grid
                  container
                  style={{
                    gap: "20px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "self-start",
                    alignSelf: "stretch",
                  }}
                >
                  {DesignationForm.map((item: any) => (
                    <Grid item>
                      <TextField
                        autoComplete={item.autoComplete}
                        name={item.name}
                        required
                        id={item.id}
                        label={item.label}
                        placeholder={item.placeholder}
                        InputLabelProps={{
                          shrink: true,
                          sx: labelStyle,
                        }}
                        error={isValid[`${item.name}`]}
                        helperText={
                          isValid[`${item.name}`]
                            ? "This field is required!"
                            : ""
                        }
                        InputProps={{
                          sx: {
                            ...inputStyle,
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: "0 0 0 1000px #fff inset",
                              WebkitTextFillColor: "inherit",
                            },
                          },
                        }}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                          handleIsValidItem(item.name);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>

                {/*                        
                        <RenderResources
                          renderData={customerResourceList}
                          isChecked={false}
                          cssClass={cssClass}
                          resourceList={resourceList}
                          handleResourceListChildren={
                            handleResourceListChildrenChange
                          }
                          handleResourceListParent={
                            handleResourceListParentChange
                          }
                        /> */}
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
};
