import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import check from "../../../image/checkmark-circle-02.svg";
import { MenuItem } from "@mui/material";
import EmployeeSearch from "../../EmployeeManagement/EmployeeSearch";
import {
  Fetch_Edit_Leave_List,
  Fetch_Leave_Type_List,
  updateLeave,
} from "../../common/services/Leave";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Leave_Unit } from "../../../redux/actions/Leave/Leave";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import TimeRangePicker from "./TimeRangePicer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface Leave_Type {
  id: string;
  leave_type_name: string;
}

interface FormValues {
  leave_period_type: string;
  leave_type: string;
  reason: string;
  employee_id: string;
  leave_session: string;
  no_of_days: number;
  timeRange: { start: Dayjs | null; end: Dayjs | null };
}

const initialFormValues: FormValues = {
  leave_period_type: "",
  leave_type: "",
  reason: "",
  employee_id: "",
  leave_session: "",
  no_of_days: 0,
  timeRange: { start: null, end: null },
};

export const EditLeave = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const leave = useSelector((state: any) => state.leave.LeaveData);
  console.log(leave, "leaveleaveleave------->>>>>>>>>>");

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [dateAll, setDateAll] = useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [selectLeaveType, setSelectLeaveType] = useState<Leave_Type[]>();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [timeRangeError, setTimeRangeError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const handleOpen = () => {
    const res = validate();
    if(res) submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/leave-holiday-management");
  };

  const dispatch = useDispatch();

  const fetchLeaveType = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Leave_Type_List(
        searchTerm,
        numOfItems,
        page
      );
      // customer_id,
      // numOfItems,
      // page,
      //sortType,
      // searchTerm
      // startDate,
      // endDate
      if (response.status === 200) {
        console.log("Data: Leave Tpye", response.data.result);
        setSelectLeaveType(response.data.result.rows);

        //setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    //setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const { api } = useParams();
  const location = useLocation();
  const id = location.state?.id;

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.reason === "") tempErrors.reason = "Reason is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value == "1")
      setFormValues({
        ...formValues,
        [name]: value,
        leave_session: "Full Day",
      });
    else if (value == "0.2")
      setFormValues({
        ...formValues,
        [name]: value,
        leave_session: "Short Day",
        no_of_days: Number(value),
      });
    else if (value == "0.5")
      setFormValues({
        ...formValues,
        [name]: value,
        leave_session: "Half Day",
        no_of_days: Number(value),
      });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const LeavePeriodType = [
    { value: "1", label: "Full Day" },
    { value: "0.5", label: "Half Day" },
    { value: "0.2", label: "Short Leave" },
  ];

  const LeaveSessionOptions = [
    { value: "First Half", label: "First Half" },
    { value: "Second Half", label: "Second Half" },
  ];

  const fetchData = async () => {
    // setLoading(true);
    try {
      const response = await Fetch_Edit_Leave_List(id);
      if (response.status === 200) {
        console.log("Data: edit leave---->>>>>", response.data);
        // setFormValues({
        //   leave_period_type:response.data.result.rows[0].no_of_days
        // })
        // setTableData(response.data.result.userData.rows);
        //setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    //setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("callll----->>>>");
    dispatch<any>(Leave_Unit(customer_id, navigate, id));
  }, []);

  useEffect(() => {
    if (leave) {
      console.log(leave?.no_of_days, "leave.leave_period_type------->>>>>");

      const fromDateTime = `${leave.from_date}T${leave.from_time}`;
      const toDateTime = `${leave.to_date}T${leave.to_time}`;

      setFormValues({
        leave_period_type: leave?.no_of_days || "",
        reason: leave.reason,
        // leave_session: leave.leave_session || '',
        employee_id: leave?.User?.employee_id,
        leave_type: leave?.leave_type_id || "",
        leave_session: leave?.leave_session || "",
        timeRange: {
          start: dayjs(fromDateTime),
          end: dayjs(toDateTime),
        },
        no_of_days: leave?.no_of_days,
      });
      setStartDate(dayjs(leave.from_date));
      setEndDate(dayjs(leave.to_date));
      setDateAll(dayjs(leave?.from_date));
    }
  }, [leave]);

  console.log(formValues.timeRange, "timerange------->>>>>>");

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const formatDate = (date: any) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        return dayjs(date).isValid() ? formattedDate : null;
      };

      const formatTime = (time: any) => {
        const formattedTime = dayjs(time).format("HH:mm");
        return dayjs(time).isValid() ? formattedTime : null;
      };

      const data = await updateLeave(
        id,
        formValues.leave_type,
        formValues.no_of_days,
        formatDate(endDate) || formatDate(dateAll),
        formatDate(startDate) || formatDate(dateAll),
        formValues.reason,
        formValues.leave_session,
        formatTime(formValues?.timeRange?.start),
        formatTime(formValues?.timeRange?.end)
      );

      if (data && data.result) {
        console.log("final data:", data);
        //toast.success(data.message)
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          navigate("/leave-holiday-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee Leave details updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-8 px-[18.5px] h-full">
        <div className="flex px-4 flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/leave-holiday-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Employee Leave Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/leave-holiday-management")}
              className="flex w-[148px] borderButton  p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg buttoncss text-white h-12"
              onClick={handleOpen}
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Update
              </div>
            </button>
          </div>
        </div>

        <div className="rounded overflow-hidden shadow-lg bg-white m-4">
          <div className="container mt-2 mx-auto p-4">
            <div className="text-[#262626] mb-4 font-[500] text-[14px] ml-4 font-['Poppins']">
              Edit Leave details
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-3 gap-4">
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="Leave Period Type"
                  name="leave_period_type"
                  value={formValues.leave_period_type}
                  onChange={handleTypeChange}
                >
                  {LeavePeriodType?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                {formValues.leave_period_type == "0.2" && (
                  <TimeRangePicker
                    label="Time Range"
                    value={formValues.timeRange}
                    onChange={(newValue) =>
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        timeRange: newValue,
                      }))
                    }
                    onError={setTimeRangeError}
                  />
                )}

                {formValues.leave_period_type == "0.5" && (
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Leave Session"
                    name="leave_session"
                    value={formValues.leave_session}
                    onChange={handleFrormChange}
                  >
                    {LeaveSessionOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="Leave Type"
                  name="leave_type"
                  value={formValues.leave_type}
                  onChange={handleFrormChange}
                >
                  {selectLeaveType?.map((option) => (
                    <MenuItem key={Number(option.id)} value={Number(option.id)}>
                      {option.leave_type_name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  id="outlined-required"
                  label="Employee Id"
                  disabled
                  value={formValues.employee_id}
                />
                {formValues.leave_period_type == "0.5" ||
                formValues.leave_period_type == "0.2" ? (
                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={dateAll}
                        onChange={(newValue) => {
                          setDateAll(newValue);
                          // setIsStart(true);
                          // setIsStartDisplay(false);
                        }}
                        className="w-[95%]"
                        minDate={dayjs()}
                      />
                    </LocalizationProvider>
                    {/* {isStartDisplay && (
                    <span className="px-4 text-red-600 text-xs font-medium">
                      Date is required
                    </span>
                  )} */}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                            if (endDate)
                              formValues.no_of_days =
                                Number(endDate?.diff(newValue, "day")) + 1;
                          }}
                          className="w-full"
                          minDate={dayjs()}
                        />
                      </LocalizationProvider>
                      {/* {isStartDisplay && (
                        <span className="px-4 text-red-600 text-xs font-medium">
                          Start Date is required
                        </span>
                      )} */}
                    </div>
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            if (startDate)
                              formValues.no_of_days =
                                Number(newValue?.diff(startDate, "day")) + 1;
                          }}
                          className="w-full"
                          minDate={dayjs()}
                        />
                      </LocalizationProvider>
                      {/* {isEndDisplay && (
                        <span className="px-4 text-red-600 text-xs font-medium">
                          End Date is required
                        </span>
                      )} */}
                    </div>
                  </>
                )}
              </div>

              <div className="grid grid-cols-1 gap-4 mt-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Reason"
                  multiline
                  rows="3"
                  name="reason"
                  value={formValues.reason}
                  onChange={handleFrormChange}
                  error={!!errors.reason}
                  helperText={errors.reason}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
