import React, { useState } from "react";
import right from "../../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import check from "../../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { createHoliday } from "../../common/services/Holiday";
import toast from "react-hot-toast";
import { error } from "console";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  description: string;
  holiday_name: string;
}

const AddHoliday = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (!formValues.holiday_name.trim())
      tempErrors.holiday_name = "Holiday name is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [isDate, setIsDate] = useState<boolean | null>(false);
  const [isDateDisplay, setIsDateDisplay] = useState<boolean | null>(false);

  const handleOpen = () => {
    !isDate && setIsDateDisplay(true);
    const res = validate();
    if(res && isDate) postData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/holiday");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/holiday");
  };

  const [formValues, setFormValues] = useState<FormValues>({
    description: "",
    holiday_name: "",
  });

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...formValues,
      [name]: "",
    });
  };

  const postData = async () => {
    setLoading(true);
    try {
      const response = await createHoliday(
        formValues.description,
        formValues.holiday_name,
        dayjs(startDate).format("YYYY-MM-DD")
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/holiday");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className="p-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Holiday Added Successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center px-4 h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/holiday")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Holiday
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">
            Holiday Details
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 mt-4 gap-4">
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      setIsDate(true);
                      setIsDateDisplay(false);
                    }}
                    className="w-full"
                    minDate={dayjs()}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isDateDisplay ? "#d32f2f" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isDateDisplay ? "#d32f2f" : "",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: isDateDisplay ? "#d32f2f" : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: isDateDisplay ? "#d32f2f" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        color: isDateDisplay ? "#d32f2f" : "",
                      },
                    }}
                  />
                </LocalizationProvider>
                {isDateDisplay && (
                  <span className="px-4 text-[#d32f2f] text-xs font-medium">
                    Date is required
                  </span>
                )}
              </div>
              <TextField
                required
                id="outlined-required"
                label="Holiday"
                defaultValue=" "
                name="holiday_name"
                value={formValues.holiday_name}
                onChange={handleFrormChange}
                error={!!errors.holiday_name}
                helperText={errors.holiday_name}
              />
            </div>

            <div className="grid grid-cols-1">
              <TextField
                required
                id="outlined-required"
                label="Description"
                defaultValue=" "
                name="description"
                multiline
                rows="3"
                value={formValues.description}
                onChange={handleFrormChange}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddHoliday;
