import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../ResetRedux/RevertAll";

interface LoginDetailsType {
  login_details: {} | null;
  isAuthenticated: boolean | false;
  onboarding_status: string;
}

interface AuthState {
  login: LoginDetailsType;
}

const initialState: AuthState = {
  login: {
    login_details: null,
    isAuthenticated: false,
    onboarding_status: "",
  },
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setLoginDetails(state, action: PayloadAction<[{}, boolean, string]>) {
      state.login = {
        login_details: action.payload[0],
        isAuthenticated: action.payload[1],
        onboarding_status: action.payload[2],
      };
      console.log(state.login);
    },
    reset(state) {
      state.login.login_details = initialState.login.login_details;
      state.login.isAuthenticated = initialState.login.isAuthenticated;
      state.login.onboarding_status = initialState.login.onboarding_status;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
