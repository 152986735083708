import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import check from "../../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import {
  createLeave,
  Fetch_Leave_Type_List,
} from "../../common/services/Leave";
import { MenuItem } from "@mui/material";
import EmployeeSearch from "../../EmployeeManagement/EmployeeSearch";
import toast from "react-hot-toast";
import TimeRangePicker from "./TimeRangePicer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface Leave_Type {
  id: string;
  leave_type_name: string;
}

interface FormSelectValues {
  leave_period_type: string;
  leave_session: string;
  leave_type: string;
  reason: string;
  no_of_days: number | null;
  timeRange: { start: Dayjs | null; end: Dayjs | null };
}

const AddLeave = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [dateAll, setDateAll] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectLeaveType, setSelectLeaveType] = useState<Leave_Type[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [timeRangeError, setTimeRangeError] = useState<string | null>(null);

  const [errors, setErrors] = useState<Partial<FormSelectValues>>({});

  const handleOpen = () => {
    !isStart && setIsStartDisplay(true);
    !isEnd && setIsEndDisplay(true);
    const res = validate();
    if (res) postData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/leave-holiday-management");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/leave-holiday-management");
  };

  const LeaveSessionOptions = [
    { value: "First Half", label: "First Half" },
    { value: "Second Half", label: "Second Half" },
  ];

  const [isStart, setIsStart] = useState<boolean | null>(false);
  const [isStartDisplay, setIsStartDisplay] = useState<boolean | null>(false);
  const [isEnd, setIsEnd] = useState<boolean | null>(false);
  const [isEndDisplay, setIsEndDisplay] = useState<boolean | null>(false);

  const validate = (): boolean => {
    let tempErrors: Partial<FormSelectValues> = {};
    if (!formSelectValues.reason.trim())
      tempErrors.reason = "Reason is required";
    if (formSelectValues.leave_type === "")
      tempErrors.leave_type = "Leave type is required";
    if (formSelectValues.leave_period_type === "")
      tempErrors.leave_period_type = "Leave Period Type is required";
    if (formSelectValues.leave_session === "")
      tempErrors.leave_session = "Leave Session os required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value == "1")
      setFormSelectValues({
        ...formSelectValues,
        [name]: value,
        leave_session: "Full Day",
      });
    else if (value == "0.2")
      setFormSelectValues({
        ...formSelectValues,
        [name]: value,
        leave_session: "Short Day",
        no_of_days: Number(value),
      });
    else if (value == "0.5")
      setFormSelectValues({
        ...formSelectValues,
        [name]: value,
        leave_session: "Half Day",
        no_of_days: Number(value),
      });

    // setFormSelectValues({
    //   ...formSelectValues,
    //   [name]: value,
    // });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormSelectValues({
      ...formSelectValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const LeavePeriodType = [
    { value: "1", label: "Full Day" },
    { value: "0.5", label: "Half Day" },
    { value: "0.2", label: "Short Leave" },
  ];

  const [formSelectValues, setFormSelectValues] = useState<FormSelectValues>({
    leave_period_type: "",
    leave_session: "",
    leave_type: "",
    reason: "",
    no_of_days: 0,
    timeRange: { start: null, end: null },
  });

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Leave_Type_List(
        searchTerm,
        numOfItems,
        page
      );
      // customer_id,
      //sortType,
      // startDate,
      // endDate
      if (response.status === 200) {
        console.log("Data: Leave Tpye", response.data.result);
        setSelectLeaveType(response.data.result.rows);

        //setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);

  const postData = async () => {
    // setLoading(true);
    try {
      const formatDate = (date: any) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        return dayjs(date).isValid() ? formattedDate : null;
      };

      const formatTime = (time: any) => {
        const formattedTime = dayjs(time).format("HH:mm");
        return dayjs(time).isValid() ? formattedTime : "00:00";
      };

      const response = await createLeave(
        selectedEmployeeId,
        formSelectValues.leave_type,
        formSelectValues.no_of_days,
        // Number(endDate?.diff(startDate, "day"))+1,
        formatDate(endDate) || formatDate(dateAll),
        formatDate(startDate) || formatDate(dateAll),
        formSelectValues.reason,
        formSelectValues.leave_session,
        formatTime(formSelectValues?.timeRange?.start),
        formatTime(formSelectValues?.timeRange?.end)
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/leave-holiday-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //setLoading(false);
  };

  console.log(formSelectValues.timeRange.start, "timerange");
  console.log(
    dayjs(endDate).format("YYYY-MM-DD") || dayjs(dateAll).format("YYYY-MM-DD"),
    "dateallll----->>>>",
    startDate,
    "timerange----start--->>>>"
  );
  console.log(
    dayjs(dateAll).format("YYYY-MM-DD"),
    "dateallll---hvhgbhbjh-->>>>"
  );

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  console.log(
    selectedEmployeeId,
    "selectedEmployeeIdselectedEmployeeId=------>>>>>>"
  );

  return (
    <div className="p-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee leave added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center px-4 h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/leave-holiday-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Employee Leave
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container h-[400px] mt-2 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                select
                label="Leave Period Type"
                name="leave_period_type"
                value={formSelectValues.leave_period_type}
                onChange={handleTypeChange}
                error={!!errors.leave_period_type}
                helperText={errors.leave_period_type}
              >
                {LeavePeriodType?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {formSelectValues.leave_period_type === "0.5" && (
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="Leave Session"
                  name="leave_session"
                  value={formSelectValues.leave_session}
                  onChange={handleFrormChange}
                >
                  {LeaveSessionOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {formSelectValues.leave_period_type === "0.2" && (
                <div className="p-0 m-0 col-span-2">
                  <TimeRangePicker
                    label="Time Range"
                    value={formSelectValues.timeRange}
                    onChange={(newValue) =>
                      setFormSelectValues((prevValues) => ({
                        ...prevValues,
                        timeRange: newValue,
                      }))
                    }
                    onError={setTimeRangeError}
                  />
                </div>
              )}
              <TextField
                required
                id="outlined-required"
                select
                label="Leave Type"
                name="leave_type"
                value={formSelectValues.leave_type}
                onChange={handleFrormChange}
                error={!!errors.leave_type}
                helperText={errors.leave_type}
              >
                {selectLeaveType?.map((option) => (
                  <MenuItem key={Number(option.id)} value={Number(option.id)}>
                    {option.leave_type_name}
                  </MenuItem>
                ))}
              </TextField>
              {/* <TextField
                required
                id="outlined-required"
                label="Employee Id"
                defaultValue=" "
              /> */}
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
              />
              {formSelectValues.leave_period_type === "0.5" ||
              formSelectValues.leave_period_type === "0.2" ? (
                <div className="flex flex-col items-start w-full pr-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={dateAll}
                      onChange={(newValue) => {
                        setDateAll(newValue);
                        // setIsStart(true);
                        // setIsStartDisplay(false);
                      }}
                      className="w-full"
                      minDate={dayjs()}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: isStartDisplay ? "#d32f2f" : "",
                          },
                          "&:hover fieldset": {
                            borderColor: isStartDisplay ? "#d32f2f" : "",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: isStartDisplay ? "#d32f2f" : "",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: isStartDisplay ? "#d32f2f" : "",
                        },
                        "& .MuiSvgIcon-root": {
                          color: isStartDisplay ? "#d32f2f" : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {/* {isStartDisplay && (
                    <span className="px-4 text-#d32f2f-600 text-xs font-medium">
                      Date is requi#d32f2f
                    </span>
                  )} */}
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                          setIsStart(true);
                          setIsStartDisplay(false);
                          if (endDate)
                            formSelectValues.no_of_days =
                              Number(endDate?.diff(newValue, "day")) + 1;
                          else formSelectValues.no_of_days = null;
                        }}
                        className="w-full"
                        minDate={dayjs()}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isStartDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isStartDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isStartDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isStartDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isStartDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isStartDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        Start Date is required
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col items-start w-full pr-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                          setIsEnd(true);
                          setIsEndDisplay(false);
                          if (startDate)
                            formSelectValues.no_of_days =
                              Number(newValue?.diff(startDate, "day")) + 1;
                          else formSelectValues.no_of_days = null;
                        }}
                        className="w-full"
                        minDate={dayjs()}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: isEndDisplay ? "#d32f2f" : "",
                            },
                            "&:hover fieldset": {
                              borderColor: isEndDisplay ? "#d32f2f" : "",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: isEndDisplay ? "#d32f2f" : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: isEndDisplay ? "#d32f2f" : "",
                          },
                          "& .MuiSvgIcon-root": {
                            color: isEndDisplay ? "#d32f2f" : "",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {isEndDisplay && (
                      <span className="px-4 text-[#d32f2f] text-xs font-medium">
                        End Date is required
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <TextField
                required
                id="outlined-required"
                label="Reason"
                multiline
                rows="3"
                name="reason"
                value={formSelectValues.reason}
                onChange={handleFrormChange}
                error={!!errors.reason}
                helperText={errors.reason}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddLeave;
