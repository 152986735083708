import React, { useState, useEffect } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';

interface MyStatefulEditorProps {
  content?: string;
  onChange?: (value: string) => void;
}

const MyStatefulEditor: React.FC<MyStatefulEditorProps> = ({ content, onChange }) => {
  const [value, setValue] = useState<EditorValue>(RichTextEditor.createEmptyValue());

  useEffect(() => {
    if (content) {
      setValue(RichTextEditor.createValueFromString(content, 'html'));
    }
  }, [content]);

  const handleChange = (value: EditorValue) => {
    setValue(value);
    if (onChange) {
      onChange(value.toString('html'));
    }
  };

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
      className='h-[300px]'
    />
  );
};

export default MyStatefulEditor;
