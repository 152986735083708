import moment from "moment";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import {
  FETCH_EDIT_LEAVE_BALANCE,
  FETCH_LEAVE,
  FETCH_LEAVE_BALANCE_LIST,
  FETCH_LEAVE_TYPE,
  FETCH_WFH,
} from "../api";
import { Dayjs } from "dayjs";

export const Fetch_Leave_List = (
  customer_id: number,
  numOfItems: number,
  page: number,
  group_id:number,
  department_id:number,
  //sortType: any,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${FETCH_LEAVE}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_List1 = (
  customer_id: number,
  
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?customer_id=${customer_id}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  return axios.get(`${FETCH_LEAVE}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Type_List = (
  searchTerm: string,
  numOfItems: number,
  page: number
) =>
  // customer_id: number,
  // numOfItems: number,
  // page: number,
  // group_id:number,
  // department_id:number,
  //sortType: any,
  // searchTerm: string,
  // startDate: Date | null,
  // endDate: Date | null
  {
    const access_token = localStorage.getItem("access_token");
    let query = `?size=${numOfItems}&page=${page}`;
    // let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
    //&size=${numOfItems}&page=${page}

    // if (sortType != undefined) {
    //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
    // }

    if (searchTerm !== "") {
      query += `&search=${searchTerm}`;
    }

    // if (group_id) {
    //   query += `&group_id=${group_id}`;
    // }

    // if (department_id) {
    //   query += `&department_id=${department_id}`;
    // }
    // if (startDate && endDate) {
    //   const start = moment(startDate).format("YYYY-MM-DD");
    //   const end = moment(endDate).format("YYYY-MM-DD");
    //   query += `&start_date=${start}&end_date=${end}`;
    // }

    return axios.get(`${FETCH_LEAVE_TYPE}${query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });
  };

// export const createLeave = async (
//   user_id:string |null,
//   leave_type_id: string | null,
//   no_of_days: number,
//   to_date: string| Dayjs | null,
//   from_date: string| Dayjs | null,
//   reason: string | undefined,
//   leave_session: string | null,
//   from_time:string|Dayjs|null,
//   to_time:string|Dayjs|null,
// ) => {
//   const access_token = localStorage.getItem("access_token");

//   // Build the data object conditionally
//   const data: any = {
//     user_id:user_id,
//     leave_type_id: leave_type_id,
//     no_of_days: no_of_days,
//     to_date: to_date,
//     from_date: from_date,
//     reason: reason,
//     from_time:from_time,
//     to_time:to_time
//   };

//   // Add leave_session only if it is not empty
//   if (leave_session) {
//     data.leave_session = leave_session;
//   }

//   if (from_time) {
//     data.from_time = from_time;
//   }

//   if (to_time) {
//     data.to_time = to_time;
//   }
//   try {
//     const response = await axios.post(`${base_api}/api/v1/leaves`, data, {
//       headers: {
//         "x-api-key": api_key,
//         Authorization: "Bearer " + access_token,
//       },
//     });

//     return response;
//   } catch (error: any) {
//     throw error;
//   }
// };

export const createLeave = async (
  user_id: string | null,
  leave_type_id: string | null,
  no_of_days: number | null,
  to_date: string | Dayjs | null,
  from_date: string | Dayjs | null,
  reason: string | undefined,
  leave_session: string | null,
  from_time: string | Dayjs | null,
  to_time: string | Dayjs | null
) => {
  const access_token = localStorage.getItem("access_token");

  // Build the data object conditionally
  const data: any = {
    user_id: user_id,
    leave_type_id: leave_type_id,
    no_of_days: no_of_days,
    to_date: to_date,
    from_date: from_date,
    reason: reason,
  };

  // Add leave_session only if it is not empty
  if (leave_session) {
    data.leave_session = leave_session;
  }

  // Add from_time only if it is not null
  if (from_time) {
    data.from_time = from_time;
  }

  // Add to_time only if it is not null
  if (to_time) {
    data.to_time = to_time;
  }

  try {
    const response = await axios.post(`${base_api}/api/v1/leaves`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Edit_Leave_List = async (id: number) => {
  const access_token = localStorage.getItem("access_token");
  const response = await axios.get(`${FETCH_LEAVE}/${id}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
  return response.data
};

export const fetchLeaveDetails = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id,"id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/leave/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_Edit_Leave_List_List = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${FETCH_LEAVE}`);
  return fetch(`${FETCH_LEAVE}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Leave_Balance_List = (numOfItems: number, page: number,searchTerm: string) =>
  // customer_id: number,
  //searchTerm: string
  // startDate: Date | null,
  // endDate: Date | null
  {
    const access_token = localStorage.getItem("access_token");
    let query = `?size=${numOfItems}&page=${page}`;
    //let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
    //&size=${numOfItems}&page=${page}

    // if (sortType != undefined) {
    //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
    // }

    if (searchTerm !== "") {
      query += `&search=${searchTerm}`;
    }

    // if (group_id) {
    //   query += `&group_id=${group_id}`;
    // }

    // if (department_id) {
    //   query += `&department_id=${department_id}`;
    // }
    // if (startDate && endDate) {
    //   const start = moment(startDate).format("YYYY-MM-DD");
    //   const end = moment(endDate).format("YYYY-MM-DD");
    //   query += `&start_date=${start}&end_date=${end}`;
    // }

    return axios.get(`${FETCH_LEAVE_BALANCE_LIST}${query}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });
  };

// export const updateLeave = async (
//   id: number,
//   leave_type_id: string,
//   no_of_days: string,
//   from_date: string | null | Dayjs,
//   to_date: string | null | Dayjs,
//   reason: string,
//   leave_session: string | null,
//   from_time: string | Dayjs | null,
//   to_time: string | Dayjs | null
// ) => {
//   const access_token = localStorage.getItem("access_token");
//   const body = {
//     leave_type_id: leave_type_id,
//     no_of_days: no_of_days,
//     from_date: from_date,
//     to_date: to_date,
//     reason: reason,
//     leave_session:leave_session,
//     from_time:from_time,
//     to_time:to_time
//   };

//   try {
//     const response = await axios.put(`${base_api}/api/v1/leaves/${id}`, body, {
//       headers: {
//         "Content-Type": "application/json",
//         "x-api-key": api_key,
//         Authorization: "Bearer " + access_token,
//       },
//     });

//     return response.data;
//   } catch (error: any) {
//     throw error;
//   }
// };

export const updateLeave = async (
  id: number,
  leave_type_id: string,
  no_of_days: number,
  from_date: string | null | Dayjs,
  to_date: string | null | Dayjs,
  reason: string,
  leave_session: string | null,
  from_time: string | Dayjs | null,
  to_time: string | Dayjs | null
) => {
  const access_token = localStorage.getItem("access_token");

  // Initialize the body with required fields
  const body: { [key: string]: any } = {
    leave_type_id: leave_type_id,
    no_of_days: no_of_days,
    from_date: from_date,
    to_date: to_date,
    reason: reason,
  };

  // Conditionally add optional fields
  if (leave_session) {
    body.leave_session = leave_session;
  }
  if (from_time) {
    body.from_time = from_time;
  }
  if (to_time) {
    body.to_time = to_time;
  }

  try {
    const response = await axios.put(`${base_api}/api/v1/leaves/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const Fetch_WFH_List = (
  numOfItems: number,
  page: number,
  searchTerm: string,
  group_id:number,
  department_id:number,
  //sortType: any,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  let query = `?size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }
  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${FETCH_WFH}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_WFH_List1 = (
 
) => {
  const access_token = localStorage.getItem("access_token");
  let query=``
 // let query = `?size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  return axios.get(`${FETCH_WFH}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Edit_Leave_Balance_List = (customerId: number) => {
  const access_token = localStorage.getItem("access_token");
  console.log("Fetching history for ticket ID:", customerId);
  console.log("Constructed URL:", `${FETCH_EDIT_LEAVE_BALANCE}`);
  return fetch(`${FETCH_EDIT_LEAVE_BALANCE}`, {
    method: "GET",
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axios.put(
      `${base_api}/api/v1/leaves/approve-reject/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};