import React from "react";
import right from "../../image/chevron-right.png";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import check from "../../image/checkmark-circle-02.svg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const ViewClients = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/leave-holiday-management");
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee Leave details Approved successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-8 px-[18.5px]">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[26px] justify-center items-center">
            <button onClick={() => navigate("/clients")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Client Details
            </span>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
          <h2 className="text-lg font-semibold mb-4">Client Details</h2>
          <div className=" bg-[#FBFBFB] p-4">
            <div className="grid grid-cols-3 p-1 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                  Client Name
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  Creative Mind
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                 Email
                </strong>
                <br />{" "}
                <span className="text-[#1D1A22] font-[500] text-[16px] ">
                  creative@gmail.com
                </span>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                 Country
                </strong>
                <br />
                <span className="text-[#1D1A22] font-[500] text-[16px] ">
                  {" "}
                  India
                </span>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                  State
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                 Uttar Pradesh
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                  Address
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  {" "}
                  RN-27 B-Block Greator Noida 
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                  Phone No
                </strong>
                <br />
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  {" "}
                 9988998899
                </strong>
              </p>
            </div>
            <div className="grid grid-cols-3 p-2 gap-4 border-b border-gray-200">
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                  Point Of Contact
                </strong>
                <br />{" "}
                <strong className="text-[#1D1A22] font-[500] text-[16px] ">
                  Wadw Warren
                </strong>
              </p>
              <p>
                <strong className="text-[#99A1B7] font-[600] text-[16px] ">
                  Status
                </strong>
                <br />{" "}
                <strong className="bg-[#D4F8C7] p-1 font-[500] text-[12px] ">
                  Active
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewClients;
