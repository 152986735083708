import { Suspense, lazy, useEffect, useState } from "react";
import logo_darkBg from "../../assets/Frame 1686551546.svg";
import role from "../../assets/images/icon 3.png";
import logo from "../../assets/group-18.svg";
import info from "../../assets/images/info.png";
import upArrow from "../../assets/images/upArrow.png";
import downArrow from "../../assets/images/downArrow.png";
import Search from "../../assets/Search.svg";
import "./sidebar.css";
import arrowLeft from "../../assets/arrowDoubleLeft.svg";
import about from "../../assets/images/Frame.png";
import SideBarCollapsed from "./SideBarCollapsed";
import { useDispatch, useSelector } from "react-redux";
import { fetchSideBarData } from "../../redux/actions/SideBar/SideBarActions";
import { useNavigate } from "react-router-dom";
import { ABOUT_GOTRUST } from "../../utils/routeConstant";
import bgsidebar from "../../image/MicrosoftTeams-image (2).png"
import { NavLink } from "react-router-dom";
import { SideBarActions } from "../../redux/reducers/SideBar/SideBarSlice";
const RenderChildren = lazy(() => import("./RenderChildren"));

// ! INTERFACES

interface SidebarData {
  resource_id: number;
  resource_name: string;
  parent_id: number;
  icon: string;
  route: string;
  children: [];
}
const Sidebar = () => {
  //! STATES
  const [data, setData] = useState<SidebarData[]>([]);
  const [collapsed, setCollapsed] = useState(false);
  const [showChildren, setShowChildren] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  //! DISPATCH & USESELECTOR
  const dispatch = useDispatch();
  const sideBarData = useSelector((state: any) => state.SideBar?.sidebar?.data);
  console.log(sideBarData,"sideBarData------>>>>>");
  console.log(data[0]?.icon,"data---->>>");
  
  
  const selected_resource_id = useSelector(
    (state: any) => state?.SideBar?.selected_resource_id
  );

  //* to handle showOptions we can create an array where the parent id can be stored in that array to show the children inside it, only show children of those parent id which is present inside the array. We will create a handler function which will be triggered on click of that parent which will remove the parent id (if present inside the array) or add the parent id (if not there).

  useEffect(() => {
    setData(sideBarData || []);
  }, [sideBarData]);

  useEffect(() => {
    if (data) {
      data.map((item: any) => {
        setShowChildren((prevState) => {
          return [...prevState, item.resource_name];
        });
      });
    }
  }, [data]);

  //! HANDLER FUNCTIONS

  const handleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
  };

  const handleShowChildren = (resource_name: string) => {
    setShowChildren((prevState: any) => {
      if (prevState.includes(resource_name)) {
        return prevState.filter((item: any) => item !== resource_name);
      } else {
        return [...prevState, resource_name];
      }
    });
  };

  //! useEffect()

  useEffect(() => {
    setData(sideBarData || []);
  }, [sideBarData]);

  const handleAbout = () => {
    navigate(`${ABOUT_GOTRUST}`);
  };

  const hoverEffect = "hover:bg-[#5D42D1] hover:text-white hover:rounded-lg";

  return (
    <div
      className={`flex sticky top-0 left-0 min-w-min mb-[32px] ${
        collapsed && !isHovered ? "sidebar-collapsed" : "sidebar-expanded"
      }`}
      style={{ height: "calc(100% - 26px)" }}
    >
      {collapsed && !isHovered ? (
        <SideBarCollapsed
          data={data}
          setIsCollapsed={handleCollapsed}
          setIsHovered={setIsHovered}
        />
      ) : (
        <div
          className={`sidebar flex text-2xl font-bold overflow-y-auto flex-col max-w-[315px] w-[315px]`}
          style={{
            scrollbarWidth: "none",
            overflow:"scroll",
            /* Firefox */ WebkitOverflowScrolling: "touch" /* iOS */,
          }}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="header1">
            <div className="header2 flex items-center">
              <img
                src={logo}
                className="logo"
                style={{ height: "35px", marginRight: "10px" }}
                alt="logo"
              />
              GO-EMS
              {/* <div className="heading">TrustRuler</div> */}
            </div>
            <button className="left_arrow_btn" onClick={handleCollapsed}>
              <img src={arrowLeft} className="h-full w-full" alt="left arrow" />
            </button>
          </div>
          <div className="flex justify-between self-center mt-3.5 text-sm text-gray-400 whitespace-nowrap rounded-md h-[36px] w-[222px] p-0.5">
            <input
              type="text"
              placeholder="Search"
              className="bg-[#192440] h-[36px] pl-2 pr-[2.5rem] border border-solid border-[rgb(214,211,209)] w-full rounded-md"
            />
          </div>
          <img
            src={Search}
            alt="Search"
            className="translate-x-[236px] translate-y-[-28px] w-6 h-6"
          />
          {/* Rendering Data for Side menu */}
          {/* <div className="flex flex-col  pl-5 w-full">
            <NavLink
              to={"/about-go-ems"}
              className={({ isActive }) =>
                `pop-up flex mb-2 flex-row ${
                  isActive ? "active-link" : "inactive-link"
                } gap-2.5 py-6 px-6 whitespace-nowrap text-[12px] font-[500] w-[241px] h-[34px] items-center`
              }
            >
              <img src={about} alt="" className="w-6 h-6" />
              About GO-EMS
            </NavLink>
            <NavLink
              to={"/role-management"}
              className={({ isActive }) =>
                `pop-up flex mb-2 flex-row ${
                  isActive ? "active-link" : "inactive-link"
                } gap-2.5 py-6 px-6 whitespace-nowrap text-[12px] font-[500] w-[241px] h-[34px] items-center`
              }
            >
              <img src={about} alt="" className="w-6 h-6" />
              Role Management
            </NavLink>{" "}
            <NavLink
              to={"/customer-management"}
              className={({ isActive }) =>
                `pop-up flex mb-2 flex-row ${
                  isActive ? "active-link" : "inactive-link"
                } gap-2.5 py-6 px-6 whitespace-nowrap text-[12px] font-[500] w-[241px] h-[34px] items-center`
              }
            >
              <img src={about} alt="" className="w-6 h-6" />
              Customer Management
            </NavLink>{" "}
            <NavLink
              to={"/company-structure"}
              className={({ isActive }) =>
                `pop-up flex mb-2 flex-row ${
                  isActive ? "active-link" : "inactive-link"
                } gap-2.5 py-6 px-6 whitespace-nowrap text-[12px] font-[500] w-[241px] h-[34px] items-center`
              }
            >
              <img src={about} alt="" className="w-6 h-6" />
              Company Structure
            </NavLink>
            <NavLink
              to={"/employee-management"}
              className={({ isActive }) =>
                `pop-up flex mb-2 flex-row ${
                  isActive ? "active-link" : "inactive-link"
                } gap-2.5 py-6 px-6 whitespace-nowrap text-[12px] font-[500] w-[241px] h-[34px] items-center`
              }
            >
              <img src={about} alt="" className="w-6 h-6" />
              Employee Management
            </NavLink>
          </div> */}



           {data?.map((item) => {
            const isNavLinkRendered =
              item?.route !== undefined && item?.route !== null;
            return (
              <div key={item.resource_id} className="flex flex-col pl-5 w-full">
                {/*Parent Div *-------------------*/}
                {isNavLinkRendered ? (
                  <NavLink
                    to={item.route}
                    className={`pop-up flex flex-row gap-2.5 py-1.5 pr-1.5 whitespace-nowrap w-[241px] h-[34px] hover:pl-2 hover:bg-[#5D42D1] hover:rounded-lg items-center ${
                      selected_resource_id === item.resource_id
                        ? "bg-[#5D42D1] rounded-lg"
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        SideBarActions.setSelectedChild(item.resource_id)
                      )
                    }
                  >
                    <img
                      src={item.icon}
                      className="flex w-5 h-5 aspect-square"
                      alt="img"
                    />
                    <div
                      style={{
                        transform: "translateY(-2.5px)",
                      }}
                      className="grow self-start py-1.5 text-slate-300 text-xs font-semibold font-['Poppins']"
                    >
                      {item.resource_name}
                    </div>
                    {item.children.length ? (
                      <button
                        onClick={() => {
                          handleShowChildren(item.resource_name);
                        }}
                      >
                        {showChildren.includes(item.resource_name) ? (
                          <img
                            src={upArrow}
                            className="w-6 aspect-square"
                            alt="Up Arrow"
                          />
                        ) : (
                          <img
                            src={downArrow}
                            className="w-3 h-2 mr-1.5 aspect-square"
                            alt="Down Arrow"
                          />
                        )}
                      </button>
                    ) : (
                      <></>
                    )}
                  </NavLink>
                ) : (
                  <div className="flex gap-2.5 py-1.5 pr-1.5 mt-2 whitespace-nowrap">
                    <img
                      src={item.icon}
                      className="w-5 h-5 mt-1 aspect-square"
                      alt="img"
                    />
                    <div className="grow justify-center self-start py-1.5 text-slate-300 text-xs font-semibold font-['Poppins']">
                      {item.resource_name}
                    </div>
                    {item.children.length ? (
                      <button
                        onClick={() => {
                          handleShowChildren(item.resource_name);
                        }}
                      >
                        {showChildren.includes(item.resource_name) ? (
                          <img
                            src={upArrow}
                            className="w-6 aspect-square"
                            alt="Up Arrow"
                          />
                        ) : (
                          <img
                            src={downArrow}
                            className="w-3 h-2 mr-1.5 aspect-square"
                            alt="Down Arrow"
                          />
                        )}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                {/*Children Div *--------------*/}
                {showChildren.includes(item.resource_name) && (
                  <Suspense fallback={<div>Loading</div>}>
                    <RenderChildren
                      children={item.children}
                      depth={1}
                      showChildrenData={showChildren}
                    />
                  </Suspense>
                )}
              </div>
            );
          })} 
        </div>
      )}
    </div>
  );
};

export default Sidebar;
