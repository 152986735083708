import React, { useState, useCallback, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UseSelector } from "react-redux";
import search from "../../assets/Search.svg";
import filter from "../../assets/filter.svg";
import download from "../../assets/download_new.svg";
import profile from "../../assets/Ellipse 580.png";
import eye from "../../assets/Crud_Icons/view.svg";
import TablePaginationDemo from "../common/Pagenation";
import Filter from "../Filter/Filter";
import Search from "../SearchBar/Search";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import { Fetch_Employee_DSR_List } from "../common/services/EmployeeDSR";
import { Chip } from "@material-ui/core";
import moment from "moment";

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface RowData {
  id: number;
  User: {
    firstName: string;
    middleName: string;
    lastName: string;
    employee_id: string;
  };
  Project: {
    id: string;
    project_name: string;
  };
  Task: {
    id: string;
    task_name: string;
  };
  date: string;
  duration: string;
}

export const EmployeeDSR = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [businessSelect, setBusinessSelect] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedBusinessUnit(option);
    console.log("Selected Business Unit:", option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedDepartmentUnit(option);
    console.log("Selected Business Unit:", option);
  };

  const fetchDSR = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Employee_DSR_List(
        customer_id,
        searchTerm,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        startDate,
        endDate
      );

      if (response.status === 200) {
        console.log("Data: DSR", response.data.result);
        setTableData(response.data.result.rows);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  console.log(tableData, "tableData-----tableData------>>>>");

  useEffect(() => {
    fetchDSR();
    console.log("after fetch page", page);
  }, [
    searchTerm,
    page,
    numOfItems,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    console.log("chek print");

    const fetchHistory = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );
        console.log(historyData, "historyData---->>>");

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    console.log("chek print");

    const fetchDepartment = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );
        console.log(historyData, "historyData department---->>>");

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  const handleFilterApply = () => {
    setStartDate(selectedDates[0]);
    setEndDate(selectedDates[1]);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    setSelectedDates([null, null]);
    setStartDate(null);
    setEndDate(null);
    // fetchDSR();
  };

  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchDSR();
  };

  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchDSR();
  };

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <div className="px-4 pt-6 pb-12 bg-[#FBFBFB]">
      <div className="flex flex-col gap-5">
        <div className="h-12">
          <div className="h-[30px]">
            <span className="font-[Poppins] font-semibold text-xl text-[#1D1A22]">
              Employee DSR
            </span>
          </div>
        </div>
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee Name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label={`${moment(selectedDates[0]).format(
                      "YYYY-MM-DD"
                    )} - ${moment(selectedDates[1]).format("YYYY-MM-DD")}`}
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text">
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <table className="border-[0.6px] border-[#EBEBEB]">
            <thead
              className="h-[46px] bg-gradient-to-r font-[Poppins] font-medium text-[14px] leading-[14px] text-[#1D1A22]"
              style={{
                background:
                  "linear-gradient(90deg, rgba(65, 101, 196, 0.2) 0%, rgba(142, 31, 210, 0.2) 100%)",
              }}
            >
              <th className="px-4">Employee Name & ID</th>
              <th className="px-4">Project Name & ID</th>
              <th className="px-4">Task ID</th>
              <th className="px-4">Date</th>
              <th className="px-4">Efforts</th>
              <th className="px-4">Action</th>
            </thead>
            <tbody className="h-[60px] border-0 border-b-[1px] border-solid border-[#EBEBEB] bg-white">
              {tableData.map((items) => (
                <tr className="hover:bg-gray-100">
                  <td className="px-4">
                    <div className="h-[40px] flex items-center gap-2.5">
                      <div className="h-full w-[40px] rounded-full">
                        <img
                          src={profile}
                          alt="profile"
                          className="h-full w-full"
                        />
                      </div>
                      <div className="h-full flex flex-col justify-center">
                        <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          {items?.User.firstName} {items?.User?.middleName}{" "}
                          {items?.User?.lastName}
                        </span>
                        <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          {items?.User?.employee_id}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="px-4">
                    <div className="flex flex-col justify-center h-full">
                      <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                        {items?.Project?.project_name}
                      </span>
                      <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                        {items?.Project?.id}
                      </span>
                    </div>
                  </td>
                  <td className="px-4">
                    <div className="flex flex-col justify-center h-full">
                      <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                        {items?.Task?.task_name}
                      </span>
                      <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                        {items?.Task?.id}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 font-[Poppins] font-medium text-[12px] leading-[11.9px] tracking-[6%] text-[#49454F]">
                    {items?.date}
                  </td>
                  <td className="px-4 font-[Poppins] font-medium text-[12px] leading-[11.9px] tracking-[6%] text-[#49454F]">
                    {items?.duration}
                  </td>
                  <td className="pl-6 pr-4">
                    <div className="flex justify-start items-center">
                      <img
                        src={eye}
                        alt="view"
                        onClick={() =>
                          navigate(`/employee-dsr-details`, {
                            state: { id: items.id },
                          })
                        }
                        className="p-0.5 hover:bg-[#E6E6E6] cursor-pointer rounded-md"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {count >= 10 && (
          <TablePaginationDemo
            count={count}
            handleItemsChange={handleNumberOfPages}
            handlePageChange={handlePageChange}
            currentPage={1}
            numOfItems={10}
          />
        )}
      </div>
      <Filter
        isDate
        isOther
        heading="Employee DSR Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};
