// import { base_api, base_api } from "../../app/Constant";

// import { base_api, base_api } from "../../utils/helperData";
export const base_api= process.env.REACT_APP_BASE_API;
console.log(base_api,"base_apibase_api---->>>>");


export const GET_ROLE_LIST = `${base_api}/api/v1/role/list`;
export const GET_ROLE_RESOURCE_LIST = `${base_api}/api/v1/resources`;
export const GET_CUSTOMER_MANAGEMENT_LIST = `${base_api}/api/v1/customer/list`;
export const GET_CUSTOMER_DETAILS = `${base_api}/api/v1/customer`;
export const SEND_CREATE_USER_DATA = `${base_api}/api/v1/user`;
export const GET_USER_ROLE_ACESS_LIST = `${base_api}/api/v1/role`;
export const FETCH_SERVICES_DATA = `${base_api}/api/v1/services`;
export const FETCH_MATURITY_DATA = `${base_api}/api/v1/ambitions`;
export const FETCH_RESULT_DATA = `${base_api}/api/v1/packages`;
export const SEND_ONBOARDING_DATA = `${base_api}/api/v1/onboarding/`;
export const FETCH_DEPT_POLICY = `${base_api}/api/v1/departments`;
export const FETCH_INDUSTRY_VERTICAL = `${base_api}/api/v1/industry-vertical`;
export const FETCH_QUESTIONS = `${base_api}/api/v1/questionnaies?industry_vertical_id=`;
export const SEND_ANSWERS = `${base_api}/api/v1/answers`;
export const FETCH_COUNTRIES = `${base_api}/api/v1/country`;
export const SEND_COMPANY_STRUCTURE_DATA = `${base_api}/api/v1/groups`;
export const FETCH_ROLE_MANAGEMENT_LIST = `${base_api}/api/v1/role/list`;
export const FETCH_DESIGNATION_MANAGEMENT_LIST=`${base_api}/api/v1/designation/list`
export const FETCH_GROUP_LISTING = `${base_api}/api/v1/groups/company-structure`;
export const FETCH_SIDEBAR = `${base_api}/api/v1/sidebar`;
//export const FETCH_SIDEBAR = `${base_api}/api/v1/resources`;
export const SEND_COMPANY_STRUCTURE_DEPARTMENT = `${base_api}/api/v1/departments`;
export const SEND_COMPANY_STRUCTURE_BUSSINESS_UNIT = `${base_api}/api/v1/business-units`;
export const SEND_COMPANY_STRUCTURE_PROCESS = `${base_api}/api/v1/processes`;

export const FETCH_ROPA_CATEGORY_LIST = `${base_api}/api/v1/ropa/categories/`;
export const FETCH_ROPA_CONTROL_LIST_BY_CATEGORY = `${base_api}/api/v1/ropa/controls`;
export const FETCH_ROPA_CONTROL_FIELD = `${base_api}/api/v1/ropa/controls/fields`;
export const ADD_ANSWER = `${base_api}/api/v1/ropa/answer`;
export const DELETE_DOCUMENT_ROPA = `${base_api}/api/v1/delete-document`;
export const UPDATE_QUESTION = `${base_api}/api/v1/ropa/controls/`;
export const UPDATE_ANWER_DOCUMENT = `${base_api}/api/v1/ropa/answer/upload-document/`;

export const FETCH_POLICY_CATEGORY = `${base_api}/api/v1/policy-category`;
export const FETCH_RELEVENT_LAW = `${base_api}/api/v1/relevant-law`;
export const FETCH_REVIEWER_DATA = `${base_api}/api/v1/user/list`;
export const FETCH_LANGUAGES = `${base_api}/api/v1/language?page=1&size=200`;
export const FETCH_DEPARTMENTS = `${base_api}/api/v1/departments/`;
export const FETCH_ENTITIES = `${base_api}/api/v1/entities/`;
export const SEND_NEW_POLICY_DATA = `${base_api}/api/v1/policy`;
export const FETCH_POLICY_DASHBOARD_STATUS = `${base_api}/api/v1/policy-dashboard/policy`;
export const FETCH_POLICY_AUTHOR = `${base_api}/api/v1/policy-dashboard/author`;
export const FETCH_POLICY_APPROVER = `${base_api}/api/v1/policy-dashboard/approver`;
export const FETCH_POLICY_REVIEWER = `${base_api}/api/v1/policy-dashboard/reviewer`;
export const FETCH_POLICY_DEPARTMENT = `${base_api}/api/v1/policy-dashboard/department`;
export const FETCH_POLICY_ENTITIES = `${base_api}/api/v1/policy-dashboard/entity`;
export const VIEW_GROUP_DETAILS = `${base_api}/api/v1/user/list`;
export const CHANGE_PASSWORD = `${base_api}/api/v1/change-password`;
export const USER_PROFILE = `${base_api}/api/v1/profile`;
export const ABOUT_US = `${base_api}/api/v1/about-us/GoTrust`;
export const FETCH_ROPA_DASHBOARD_DEPT = `${base_api}/api/v1/ropa-dashboard`;
export const FETCH_ROPA_DASHBOARD_LAW = `${base_api}/api/v1/ropa-organization-role`;
export const FETCH_ROPA_DASHBOARD_THIRD_PARTY = `${base_api}/api/v1/ropa/third-party`;
export const FETCH_ROPA_DASHBOARD_PERSONAL_DATA = `${base_api}/api/v1/ropa/peronal-data`;
export const FETCH_ROPA_DASHBOARD_DATA_SYSTEM = `${base_api}/api/v1/ropa/data-system`;
export const FETCH_ROPA_DASHBOARD_ORG_ROLE = `${base_api}/api/v1/ropa/organisation-role`;
export const FETCH_ROPA_DASHBOARD_DPIA = `${base_api}/api/v1/ropa/dpia-requirement`;
export const FETCH_ROPA_BASIC_INFO = `${base_api}/api/v1/ropa/basic-info`;
export const SEND_ROPA_BASIC_INFO_ANSWERS = `${base_api}/api/v1/ropa/basic-info/answer/`;
export const UPLOAD_PROFILE = `${base_api}/api/v1/upload-profile`;
export const ROPA_SUBMIT = `${base_api}/api/v1/ropa/submit/`;
export const DELETE_QUESTION = `${base_api}/api/v1/ropa/controls/`;
export const ROPA_ACTIVITY = `/privacy/ropa/`;
export const ROPA_ADD_COLLABORATOR = `${base_api}/api/v1/ropa/collaborator`;
export const STORE_REVIEW = `${base_api}/api/v1/ropa/review/`;
export const ALL_TICKETS = `${base_api}/api/v1/tickets`;
export const TICKET_DETAILS = `${base_api}/api/v1/ticket`;
export const ADD_COMMENT = `${base_api}/api/v1/ticket/comment`;
export const TICKET_PRIORITY = `${base_api}/api/v1/tickets-priority`;
export const UPLOAD_ATTACHMENT = `${base_api}/api/v1/create-ticket`;
export const SUPPORT_DASHBOARD = `${base_api}/api/v1/ticket-dashboard`;
export const SUPPORT_TICKET_HISTORY = `${base_api}/api/v1/ticket/history`;
export const NORMAL_USER_TODO = `${base_api}/api/v1/ticket/to-do`;
export const FETCH_POLICY_LIST = `${base_api}/api/v1/ropa/policy-list`;
export const FETCH_ROPA_AUDIT_LOG = `${base_api}/api/v1/ropa/audit-log`;
export const ROPA_REVIEW_SUBMIT = `${base_api}/api/v1/ropa/review/submit/`;

// Employee 

export const SELECT_BUSINESS_UNIT=`${base_api}/api/v1/group-list`
export const SELECT_DEPARTMENT_UNIT=`${base_api}/api/v1//departments`
export const SELECT_EMPLOYEE_LIST=`${base_api}/api/v1/user/lists`
export const TEAM_MANAGER=`${base_api}/api/v1/departments-user`
export const REPORTING_MANAGER=`${base_api}/api/v1/user/list`
export const SELECT_DESIGNATION=`${base_api}/api/v1/designation/list`
export const SELECT_ROLE=`${base_api}/api/v1/role/list`

// Exit Employee

export const FETCH_EXIT_EMPLOYEE=`${base_api}/api/v1/user-exit/list`

// Attendence API

export const FETCH_ATTENDENCE_LIST=`${base_api}/api/v1/attendance`


//Privacy and Data Protection - All Policies - View Policy
export const POLICY_DATA = `${base_api}/api/v1/policy/`;
export const FETCH_POLICY_AUDIT_LOG = `${base_api}/api/v1/policy/audit-policy`;
export const POLICY_REVIEW = `${base_api}/api/v1/policy/review/`;
export const UPLOAD_POLICY_ATTACHMENT = `${base_api}/api/v1/policy/upload-document/`;
export const ADD_QUESTION = `${base_api}/api/v1/ropa/controls`;


export const UPDATE_DEPARTMENT_DATA = `${base_api}/api/v1/departments`;
export const UPDATE_PROCESS_DATA = `${base_api}/api/v1/processes`;

// Leave and Holiday

export const FETCH_LEAVE=`${base_api}/api/v1/leaves`;
export const FETCH_WFH=`${base_api}/api/v1/wfh`;
export const FETCH_LEAVE_TYPE=`${base_api}/api/v1/leave-types`
export const FETCH_LEAVE_BALANCE_LIST=`${base_api}/api/v1/leave-balance`
export const FETCH_HOLIDAY_LIST=`${base_api}/api/v1/holiday`

// Expense Management

export const FETCH_EXPENSE_TYPE_LIST=`${base_api}/api/v1/expenses-category`
export const FETCH_EMPLOYEE_EXPENSE_TYPE=`${base_api}/api/v1/expenses`

export const FETCH_CLIENTS_LIST=`${base_api}/api/v1/client`
export const FETCH_EDIT_CLIENT=`${base_api}/api/v1/client/`
export const FETCH_EDIT_LEAVE_BALANCE=`${base_api}/api/v1/leave-balance-details`

export const FETCH_PROJECT_LIST=`${base_api}/api/v1/project`
export const FETCH_RESOURCES_LIST=`${base_api}/api/v1/project-resources`
export const FETCH_TASk_LIST=`${base_api}/api/v1/task`

export const FETCH_DASHBOARD_WFH_LIST=`${base_api}/api/v1/wfh-dashboard`
export const FETCH_LEAVE_DASHBORAD_LIST=`${base_api}/api/v1/leave-dashboard`
export const FETCH_BIRTHDAY_ANNIVERSARY_LIST=`${base_api}/api/v1/bday-anniversary-dashboard`

export const FETCH_COMPANY_DETAILS=`${base_api}/api/v1/company`
export const FETCH_POLICY_CATEGORY_LIST=`${base_api}/api/v1/policy-category`
export const FETCH_POLICY_LIST_LIST=`${base_api}/api/v1/policy`


//Assessment - Privacy
export const COMMON_ENDPOINT = `${base_api}/api/v1`;
export const FORGET_PASSWORD= `${base_api}/api/v1/forgot-password`

// Employee DSR
export const FETCH_EMPLOYEE_DSR= `${base_api}/api/v1/employee-timesheet`
