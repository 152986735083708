// import React from 'react'

// const EditWFH = () => {
//   return (
//     <div>EditWFH</div>
//   )
// }

// export default EditWFH

import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import check from "../../image/checkmark-circle-02.svg";
import { MenuItem } from "@mui/material";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import { fetchEditWFH, updateWFH } from "../common/services/WFH";
import axios from "axios";
import toast from "react-hot-toast";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  leave_period_type: string;
  leave_session: string;
  reason: string;
  employee_id: string;
}

const EditWFH = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [dateAll, setDateAll] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    leave_period_type: "",
    leave_session: "",
    reason: "",
    employee_id: "",
  });

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = () => {};

  const LeavePeriodType = [
    { value: "1", label: "Full Day" },
    { value: "0.5", label: "Half Day" },
  ];

  const LeaveSessionOptions = [
    { value: "First Half", label: "First Half" },
    { value: "Second Half", label: "Second Half" },
  ];

  const location = useLocation();
  const id = location.state?.id;
  console.log(location, "ide----->>>>>");

  console.log(
    formValues.leave_period_type,
    "check period tyope",
    formValues.leave_session
  );

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if(value == "1") setFormValues({
      ...formValues,
      [name]: value,
      leave_session: "Full Day"
    })
  };

  const handleOpen = () => {
    submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/work-from-home");
  };

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditWFH(id)
      .then((data) => {
        console.log(data.result, "data--res----edit--->>>>");
        if (data.result) {
          // setUpdateData(data);
          console.log(data.result, "data.result----->>>>>wfh--->>>");

          setFormValues({
            leave_period_type: data.result?.no_of_days,
            leave_session: data.result?.wfh_session,
            reason: data.result?.reason,
            employee_id: data.result?.user_id,
          });
          setStartDate(dayjs(data.result?.from_date));
          setDateAll(dayjs(data.result?.from_date));
          setEndDate(dayjs(data.result?.to_date));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [id]);

  const submitChanges = async () => {
    setLoading(true);
    try {
      const formatDate = (date: any) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        return dayjs(date).isValid() ? formattedDate : null;
      };

      const data = await updateWFH(
        id,
        formValues.leave_period_type,
        formValues.reason,
        formatDate(endDate) || formatDate(dateAll),
        formatDate(startDate) || formatDate(dateAll),
        formValues.employee_id,
        formValues.leave_session
      );

      if (data && data.result) {
        console.log("final data:", data);
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/work-from-home");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee WFH details updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-8 px-[18.5px] h-full">
        <div className="flex flex-row items-center px-4 h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/work-from-home")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Employee Work From Home Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/work-from-home")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] borderButton justify-center items-center self-stretch rounded-lg h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              //   onClick={() => handleCurrentPageChange("addNew")}
            >
              <div
                onClick={handleOpen}
                className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              >
                Update
              </div>
            </button>
          </div>
        </div>

        <div className="rounded overflow-hidden shadow-lg bg-white m-4">
          <div className="container mt-2 mx-auto p-4">
            <div className="text-[#262626] font-[500] text-[14px] mb-6 ml-4 font-['Poppins']">
              Exit Leave details
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-3 gap-4">
                <TextField
                  required
                  id="outlined-required"
                  select
                  label="WFH Period"
                  name="leave_period_type"
                  defaultValue="Hello World"
                  value={formValues.leave_period_type}
                  onChange={handleFormChange}
                >
                  {LeavePeriodType?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {formValues.leave_period_type == "0.5" && (
                  <TextField
                    required
                    id="outlined-required"
                    select
                    label="Leave Session"
                    name="leave_session"
                    value={formValues.leave_session}
                    onChange={handleFrormChange}
                  >
                    {LeaveSessionOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                <TextField
                  required
                  id="outlined-required"
                  label="Employee Id"
                  disabled
                  defaultValue=" "
                  value={formValues.employee_id}
                />

                {formValues.leave_period_type === "0.5" ? (
                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        value={dateAll}
                        onChange={(newValue) => {
                          setDateAll(newValue);
                          // setIsStart(true);
                          // setIsStartDisplay(false);
                        }}
                        //className="w-full"
                        minDate={dayjs()}
                      />
                    </LocalizationProvider>
                    {/* {isStartDisplay && (
                    <span className="px-4 text-#d32f2f-600 text-xs font-medium">
                      Date is requi#d32f2f
                    </span>
                  )} */}
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                            //  setIsStart(true);
                            // setIsStartDisplay(false);
                          }}
                          className="w-full"
                          minDate={dayjs()}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="flex flex-col items-start w-full pr-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            //  setIsEnd(true);
                            //  setIsEndDisplay(false);
                          }}
                          className="w-full"
                          minDate={dayjs()}
                        />
                      </LocalizationProvider>
                    </div>
                  </>
                )}
              </div>

              <div className="grid grid-cols-1">
                <TextField
                  required
                  id="outlined-required"
                  label="Reason"
                  multiline
                  rows="3"
                  defaultValue=" "
                  name="reason"
                  value={formValues.reason}
                  onChange={handleFrormChange}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWFH;
