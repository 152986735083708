import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import check from "../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import { createAttendence } from "../common/services/Attendence";
import moment from "moment";
import toast from "react-hot-toast";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const AddAttendence = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = useState<number>(0);

  const [isCheckIn, setIsCheckIn] = useState<boolean | null>(false);
  const [isCheckInDisplay, setIsCheckInDisplay] = useState<boolean | null>(
    false
  );
  const [isCheckOut, setIsCheckOut] = useState<boolean | null>(false);
  const [isCheckOutDisplay, setIsCheckOutDisplay] = useState<boolean | null>(
    false
  );

  const handleOpen = () => {
    !isCheckIn && setIsCheckInDisplay(true);
    !isCheckOut && setIsCheckOutDisplay(true);
    checkIn?.isBefore(checkOut)
      ? postData()
      : alert("CheckIn time cannot be after CheckOut time");
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/attendance-management");
  };
  const handleCancel = () => {
    navigate("/attendance-management");
  };

  const [checkIn, setCheckIn] = React.useState<Dayjs | null>(null);
  const [checkOut, setCheckOut] = React.useState<Dayjs | null>(null);
  const [date, setDate] = React.useState<string>();
  const [error, setError] = React.useState<string | null>(null);
  const maxDate = dayjs();

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEmployeeSelect = (employeeId: string) => {
    // setSelectedEmployeeId(employeeId);
  };

  const handleEmployeeSelectId = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
  };

  const postData = async () => {
    // setLoading(true);
    try {
      const response = await createAttendence(
        selectedEmployeeId,
        "CHECKOUT",
        checkOut,
        checkIn,
        date
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/attendance-management");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //setLoading(false);
  };

  const handleCheckInChange = (newValue: Dayjs | null) => {
    setIsCheckIn(true);
    setIsCheckInDisplay(false);
    setCheckIn(newValue);
    console.log(checkIn);

    setDate(newValue?.format("YYYY-MM-DD"));
  };

  const handleCheckOutChange = (newValue: Dayjs | null) => {
    setIsCheckOut(true);
    setIsCheckOutDisplay(false);
    setCheckOut(newValue);
    console.log(checkOut);
  };
  console.log(selectedEmployeeId, "chhghgcfcgbfc-------->>");
  console.log(date, "chhghgcfcgbfc-------->>");

  return (
    <div className="p-8">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Attendence added successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center px-4 h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/attendance-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-['Poppins'] text-xl font-semibold leading-normal">
            Add Employee Attendance
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/attendance-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-['Poppins'] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          {/* <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">Sick Leave Details</div> */}
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 h-[300px] mt-4 gap-4">
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
              />
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="CheckIn Time"
                    value={checkIn}
                    onChange={(e) => handleCheckInChange(e)}
                    maxDate={maxDate}
                    className="w-full"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isCheckInDisplay ? "red" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isCheckInDisplay ? "red" : "",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: isCheckInDisplay ? "red" : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: isCheckInDisplay ? "red" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        color: isCheckInDisplay ? "red" : "",
                      },
                    }}
                  />
                </LocalizationProvider>
                {isCheckInDisplay && (
                  <span className="px-4 text-red-600 text-xs font-medium">
                    CheckIn Date is required
                  </span>
                )}
              </div>

              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="CheckOut Time"
                    views={["year", "month", "day", "hours", "minutes"]}
                    value={checkOut}
                    onChange={handleCheckOutChange}
                    maxDate={maxDate}
                    className="w-full"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isCheckOutDisplay ? "red" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isCheckOutDisplay ? "red" : "",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: isCheckOutDisplay ? "red" : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: isCheckOutDisplay ? "red" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        color: isCheckOutDisplay ? "red" : "",
                      },
                    }}
                  />
                </LocalizationProvider>
                {isCheckOutDisplay && (
                  <span className="px-4 text-red-600 text-xs font-medium">
                    CheckOut Date is required
                  </span>
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddAttendence;
