// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import { useNavigate } from "react-router-dom";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import { DataGrid, GridColDef } from "@mui/x-data-grid";
// import React from "react";
// import upload from "../../assets/plus 1.png";
// import plus from "../../assets/up-loading 1.png";
// import usePagination from "@mui/material/usePagination";
// import { styled } from "@mui/material/styles";
// import left from "../../image/icon16arrow-left.svg";
// import right from "../../image/icon16arrow-right.svg";
// import arr from "../../image/icon16chevron-down.svg";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import "./employee.css";
// //import { Button } from "@mui/material";
// import profile from "../../assets/Ellipse 580.png";
// import eye from "../../image/44 Visibility.png";
// import edit from "../../image/Group 1000006565.png";
// import del from "../../image/Vector.png";
// import TabPanel from "@mui/lab/TabPanel";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";

// const List = styled("ul")({
//   listStyle: "none",
//   padding: 0,
//   margin: 0,
//   display: "flex",
//   gap: "8px",
// });

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   //   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: "14px",
// };

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// function createData(
//   name: string,
//   calories: number,
//   fat: number,
//   carbs: number,
//   protein: number
// ) {
//   return { name, calories, fat, carbs, protein };
// }

// // const rows = [
// //   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
// //   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
// //   createData("Eclair", 262, 16.0, 24, 6.0),
// //   createData("Cupcake", 305, 3.7, 67, 4.3),
// //   createData("Gingerbread", 356, 16.0, 49, 3.9),
// // ];

// function createData1(name:any, id:any, department:any, role:any, actions:any) {
//   return { name, id, department, role, actions };
// }

// const rows = [
//   createData1(
//     "Cameron Williamson",
//     "EP12345",
//     "Development",
//     "Project Manager",
//     [
//       { imgSrc: eye, alt: "View", onClick: null },
//     //  { imgSrc: edit, alt: "Edit", onClick: handleEdit },
//      // { imgSrc: del, alt: "Delete", onClick: handleOpen },
//     ]
//   ),
//   // Add more rows as needed
// ];

// const Button = styled("button")(({ selected }: { selected: boolean }) => ({
//   padding: "6px 8px",
//   borderRadius: "8px", // Adds border radius
//   border: selected ? "2px solid #3f51b5" : "1px solid #ccc", // Adds border
//   fontWeight: selected ? "bold" : "normal",
//   backgroundColor: selected ? "#e3f2fd" : "#f1f1f1", // Optional: background color for selected item
//   cursor: "pointer",
//   "&:hover": {
//     backgroundColor: "#f1f1f1", // Optional: hover effect
//   },
// }));

// const IconButton = styled(Button)({
//   padding: "8px", // Adjust padding to fit the icon
// });

// export const Employee = () => {
//   const navigate = useNavigate();
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => {
//     setOpen(false);
//     navigate("/employee-management");
//   };
//   const AddEmployee = () => {
//     navigate("/add-employee");
//   };

//   const { items } = usePagination({
//     count: 10,
//   });
//   const [value, setValue] = React.useState("1");

//   const handleEdit = () => {
//     navigate("/edit-employee");
//   };

//   const handleCancel = () => {
//     setOpen(false);
//   };
//   const handleNext = () => {
//     navigate("/employee-exit-procedure");
//   };

//   const TableBodyconst = () => {
//     return (
//       <tr className="w-full mt-4 flex px-2.5 py-1 justify-between items-center border-[0.6px] border-solid border-[#E0E3E7] h-10 hover:cursor-pointer">
//         <td>
//           <div className="flex space-x-2 items-center">
//             <img className="w-8 h-8" src={profile} alt="" />
//             <span className="text-[#1960CE] font-[500] text-[12px]">
//               Cameron Williamson
//             </span>
//           </div>
//         </td>
//         <td className="text-[#3F4254] font-[500] text-[12px]">EP12345</td>
//         <td className="text-[#3F4254] font-[500] text-[12px]">Development</td>
//         <td className="text-[#3F4254] font-[500] text-[12px]">
//           Project Manager
//         </td>
//         <td>
//           <div className="flex space-x-6 items-center">
//             <img src={eye} alt="" />
//             <img onClick={handleEdit} src={edit} alt="" />
//             <img onClick={handleOpen} src={del} alt="" />
//           </div>
//         </td>
//       </tr>
//     );
//   };

//   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
//     setValue(newValue);
//   };
//   return (
//     <>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Typography
//             className="text-[#1D1A22] font-[Poppins] font-[400] text-24px"
//             style={{ textAlign: "center" }}
//             id="modal-modal-title"
//             variant="h6"
//             component="h2"
//           >
//             Delete Employee Details
//           </Typography>
//           <Typography
//             style={{ textAlign: "center" }}
//             id="modal-modal-description"
//             sx={{ mt: 2 }}
//             className="text-[#1D1A22] font-[Poppins] font-[400] text-20px"
//           >
//             Are you sure you want to delete this details?
//           </Typography>
//           <Typography sx={{ mt: 2 }} className="flex justify-center space-x-2">
//             <button
//               className="border-2 rounded-[8px] p-2"
//               onClick={handleCancel}
//             >
//               Cancel
//             </button>
//             <button className="border-2 p-2 rounded-[8px] buttoncss text-white">
//               Yes Delete
//             </button>
//           </Typography>
//         </Box>
//       </Modal>
//       <div className="flex flex-col h-[calc(100% - 100px)] p-6 bg-[#FBFBFB]">
//         <div className="flex justify-between">
//           <Box>
//             <TabContext value={value}>
//               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//                 <TabList
//                   onChange={handleChange}
//                   aria-label="lab API tabs example"
//                 >
//                   <Tab
//                     className="text-[#89888E]"
//                     label="Employee List"
//                     value="1"
//                   />
//                   <Tab label="Exit Procedure" value="2" />
//                 </TabList>
//               </Box>
//               {/* <TabPanel value="1">Item One</TabPanel>
//               <TabPanel value="2">Item Two</TabPanel> */}
//             </TabContext>
//           </Box>
//           {/* <div className="flex space-x-4">
//           <div className="">Employee List</div>
//           <div>Initiate Exit</div>
//         </div> */}
//           <div className="flex space-x-4">
//             <div>
//               <button className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg">
//                 <img className="w-4 h-4 mr-2" src={plus} alt="" />
//                 Bulk Upload
//               </button>
//             </div>
//             <div>
//               <button
//                 onClick={AddEmployee}
//                 className="flex items-center py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
//               >
//                 <img className="w-4 h-4 mr-2" src={upload} alt="" />
//                 Add Employee
//               </button>
//             </div>
//           </div>
//         </div>

//         {/* <div className="mt-6 flex justify-between">
//         <div className="flex space-x-4 items-center">
//           <div className="w-[40%] flex justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA] bg-white">
//             <input
//               type="text"
//               placeholder="Search"
//               className="text-[#3F4254] w-[95%] outline-none rounded-md py-2 pl-2.5 font-[Poppins] text-[13px] font-normal leding-normal"
//              // onChange={updateSearchTerm}
//             />
//             <img
//               src={search}
//               alt="search sign"
//               className="h-[19px] w-[19px] shrink-0"
//             />
//           </div>
//           <div className="border-2 rounded-[6px] text-12px font-[600] text-[#79747E]">Select Business Unit</div>
//           <div>Select Department</div>
//         </div>

//         <div>
//           <button>Download List</button>
//         </div>
//       </div> */}

//         <div className="flex items-center space-x-4 mt-6">
//           {/* Search Input */}
//           <div className="flex items-center border border-gray-300 rounded-lg">
//             <input
//               type="text"
//               placeholder="Search here with name and Id"
//               className="py-2 px-3 outline-none rounded-l-lg"
//             />
//             <button className="px-3">
//               <svg
//                 className="w-5 h-5 text-gray-500"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M21 21l-4.35-4.35M17.65 17.65a7.5 7.5 0 111.5-1.5l4.35 4.35"
//                 ></path>
//               </svg>
//             </button>
//           </div>

//           {/* Dropdowns */}
//           <select className="py-2 px-3 border text-[#79747E] border-gray-300 rounded-lg">
//             <option>Select Business Unit</option>
//             {/* Add more options here */}
//           </select>
//           <select className="py-2 px-3 border text-[#79747E] border-gray-300 rounded-lg">
//             <option>Select Department</option>
//             {/* Add more options here */}
//           </select>

//           {/* Download Button */}
//           <div>
//             <button className="flex ml-[140px] items-center py-2 px-4 border-2 border-[#9595f7] text-[#6363f5] text-[14px]  rounded-lg">
//               <svg
//                 className="w-5"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M12 4v12m0 0l-3-3m3 3l3-3m-6 4h6"
//                 ></path>
//               </svg>
//               Download list
//             </button>
//           </div>
//         </div>

//         {/* <div className="px-0 py-0 mt-6 w-full overflow-x-auto overflow-y-auto table_main_content">
//           <table className="mt-0 w-full min-w-max text-left table-auto table">
//             <thead className="sticky top-0">
//               <tr className="flex flex-row justify-between w-full items-center pl-6 pr-2.5 py-2.5 rounded-[3px] bg-[#E1E1E1] gap-8 h-11">
//                 <th className="flex text-[14px] text-[#1D1A22] cursor-pointer transition-colors items-center ">
//                   Name
//                 </th>
//                 <th className="flex text-[14px] text-[#1D1A22] cursor-pointer transition-colors items-center ">
//                   EMP ID
//                 </th>
//                 <th className="flex text-[14px] text-[#1D1A22] cursor-pointer transition-colors items-center ">
//                   Department
//                 </th>
//                 <th className="flex text-[14px] text-[#1D1A22] cursor-pointer transition-colors items-center ">
//                   Designation
//                 </th>
//                 <th className="flex text-[14px] text-[#1D1A22] cursor-pointer transition-colors items-center ">
//                   Action
//                 </th>
//               </tr>
//             </thead>

//             <tbody>
//               <TableBodyconst />
//               <TableBodyconst />
//               <TableBodyconst />
//               <TableBodyconst />
//               <TableBodyconst />
//               <TableBodyconst />
//             </tbody>
//           </table>
//         </div> */}

//         <div className="mt-6">
//         <TableContainer component={Paper}>
//   <Table sx={{ minWidth: 700 }} aria-label="customized table">
//     <TableHead>
//       <TableRow>
//         <StyledTableCell>Name</StyledTableCell>
//         <StyledTableCell align="right">ID</StyledTableCell>
//         <StyledTableCell align="right">Department</StyledTableCell>
//         <StyledTableCell align="right">Role</StyledTableCell>
//         <StyledTableCell align="right">Actions</StyledTableCell>
//       </TableRow>
//     </TableHead>
//     <TableBody>
//       {rows.map((row) => (
//         <StyledTableRow key={row.id}>
//           <StyledTableCell component="th" scope="row">
//             <div className="flex space-x-2 items-center">
//               <img className="w-8 h-8" src={profile} alt="" />
//               <span className="text-[#1960CE] font-[500] text-[12px]">
//                 {row.name}
//               </span>
//             </div>
//           </StyledTableCell>
//           <StyledTableCell align="right" className="text-[#3F4254] font-[500] text-[12px]">
//             {row.id}
//           </StyledTableCell>
//           <StyledTableCell align="right" className="text-[#3F4254] font-[500] text-[12px]">
//             {row.department}
//           </StyledTableCell>
//           <StyledTableCell align="right" className="text-[#3F4254] font-[500] text-[12px]">
//             {row.role}
//           </StyledTableCell>
//           <StyledTableCell align="right">
//             <div className="flex space-x-6 items-center">
//               {row.actions.map((action:any, index:any) => (
//                 <img
//                   key={index}
//                   src={action.imgSrc}
//                   alt={action.alt}
//                   onClick={action.onClick}
//                   className={action.onClick ? "hover:cursor-pointer" : ""}
//                 />
//               ))}
//             </div>
//           </StyledTableCell>
//         </StyledTableRow>
//       ))}
//     </TableBody>
//   </Table>
// </TableContainer>

//         </div>

//         <div className="mt-8 mb-6">
//           <nav className="flex items-center justify-end">
//             <List>
//               {items.map(({ page, type, selected, ...item }, index) => {
//                 let children = null;

//                 if (type === "start-ellipsis" || type === "end-ellipsis") {
//                   children = "…";
//                 } else if (type === "page") {
//                   children = (
//                     <Button
//                       className="text-[12px] font-[400]"
//                       type="button"
//                       selected={selected}
//                       {...item}
//                     >
//                       {page}
//                     </Button>
//                   );
//                 } else if (type === "next" || type === "previous") {
//                   const imgSrc = type === "next" ? `${right}` : `${left}`;
//                   const imgAlt = type === "next" ? "Next" : "Previous";
//                   children = (
//                     <IconButton type="button" selected={false} {...item}>
//                       <div className="flex">
//                         <img
//                           src={imgSrc}
//                           alt={imgAlt}
//                           style={{ width: "16px", height: "16px" }}
//                         />
//                         <span className="text-[12px] font-[600]">{imgAlt}</span>
//                       </div>
//                       <span className="sr-only">{type}</span>{" "}
//                       {/* Optional: for screen readers */}
//                     </IconButton>
//                   );
//                 } else {
//                   children = (
//                     <Button type="button" selected={false} {...item}>
//                       {type}
//                     </Button>
//                   );
//                 }

//                 return <li key={index}>{children}</li>;
//               })}
//             </List>

//             <div className="border-2 ml-8 items-center rounded-[6px] p-1 border-[#EDEDED] font-[600] text-[12px] flex">
//               100 per page
//               <img className="w-4" src={arr} alt="" />
//             </div>
//           </nav>
//         </div>
//       </div>
//     </>
//   );
// };

import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";
import { ExitProcedure } from "./ExitProcedure";
import EmployeeList from "./EmployeeList";
import "./employee.css";
import upload from "../../assets/plus 1.png";
import plus from "../../assets/bulk_upload.svg";
import maskPlus from "../../image/Mask group.svg";

// const EmployeeList: React.FC = () => {
//   return <div>Employee List</div>;
// };

// const ExitProcedure: React.FC = () => {
//   return <div><ExitProcedure/></div>;
// };

export const Employee = () => {
  const history = useNavigate();
  const [value, setValue] = React.useState<string>(
    localStorage.getItem("tabValue") || "1"
  );
  const [store, setStore] = useState("");
  const AddEmployee = () => {
    history("/add-employee");
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    localStorage.setItem("tabValue", newValue);
    if (newValue === "1") {
      history("/employee-management");
      setStore(newValue);
      // history.push('/employee-list');
    } else if (newValue === "2") {
      history("/exit-procedure");
      setStore(newValue);
    }
  };

  const getIndicatorClass = () => {
    switch (localStorage.getItem("tabValue") || "1") {
      case "1":
        return "indicator-employee-management";
      case "2":
        return "indicator-exit-procedure";
      default:
        return "indicator-default";
    }
  };

  const handlenavigate = () => {
    history("/add-exit");
  };
  return (
    <div className="px-4 pt-6 pb-12">
      <div className="">
        <Box>
          <TabContext value={value}>
            <div className="flex justify-between">
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{ className: getIndicatorClass() }}
                  sx={{
                    "& .MuiTab-root": {
                      color: "#89888E",
                      fontFamily: "poppins",
                    },
                    "& .Mui-selected": {
                      color: "#000 !important",
                      fontFamily: "poppins",
                    },
                  }}
                >
                  <Tab
                    className="text-[#89888E]"
                    label="Employee List"
                    value="1"
                  />
                  <Tab label="Exit Procedure" value="2" />
                </TabList>
              </Box>

              {window.location.pathname === "/employee-management" ? (
                <div className="flex space-x-4">
                  <div>
                    <button className="flex items-center font-[600] text-[14px] font-[Poppins] py-2 px-4 buttoncss text-white rounded-lg">
                      <img className="w-4 h-4 mr-2" src={plus} alt="" />
                      Bulk Upload
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={AddEmployee}
                      className="flex items-center py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={maskPlus}
                        alt="plus icon"
                      />
                      Add Employee
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    onClick={handlenavigate}
                    className="flex items-center py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
                  >
                    <img
                      className="w-5 h-5 mr-2"
                      src={maskPlus}
                      alt="plus icon"
                    />
                    Initiate Exit procedure
                  </button>
                </div>
              )}
            </div>
            <TabPanel className="tabpanel" value="1">
              <EmployeeList />
            </TabPanel>
            <TabPanel className="tabpanel" value="2">
              <ExitProcedure />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};
