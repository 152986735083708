import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MyStatefulEditor from "./EditEditor";
import FileUpload from "../ExpenseManagement/EditFileUoload";
import TextFieldWithPopup from "./EditTextFeildPopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import right from "../../image/chevron-right.png";
import { fetchEditTask, updateTask } from "../common/services/Project";
import toast from "react-hot-toast";
import axios from "axios";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

interface FormValues {
  task_name: string;
  estimated_hours: string;
  actual_hours: string;
  status: string;
}

const EditTask = () => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);
  const [editorContent, setEditorContent] = useState<string>("");
  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [projectID, setProjectId] = useState();
  const [loading, setLoading] = useState(false);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; project_id: number }[]
  >([]);

  const navigate = useNavigate();

  const param = useParams();
  console.log(param.id, "param----->>>>>>>");

  const location = useLocation();
  const id = location.state?.id;

  const Status = [
    { value: "upcoming", label: "Up Coming" },
    { value: "ongoing", label: "On Going" },
    { value: "completed", label: "Completed" },
    { value: "onhold", label: "On Hold" },
  ];

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    console.log("Files updated:", files);
  };

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([]);

  const handleSelectedEmployeesChange = (ids: number[]) => {
    // setSelectedEmployeeIds(ids);
    console.log("140", ids);

    if (!param.id) {
      console.error("param.id is undefined");
      return;
    }

    const projectId = parseInt(param.id);
    if (isNaN(projectId)) {
      console.error("param.id is not a valid number");
      return;
    }

    const newResources = ids?.map((id) => ({
      user_id: id,
      project_id: projectId,
    }));
    setProjectResources([...newResources]);
  };

  // console.log("111", );
  

  const [formValues, setFormValues] = useState<FormValues>({
    task_name: "",
    estimated_hours: "",
    actual_hours: "",
    status: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.task_name === "")
      tempErrors.task_name = "Task Name is required";
    if (formValues.status === "") tempErrors.status = "Task Status is required";
    if (formValues.estimated_hours === "")
      tempErrors.estimated_hours = "Estimated Hours are required";
    if (formValues.actual_hours === "")
      tempErrors.actual_hours = "Actual Hours are required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditTask(id)
      .then((data) => {
        // console.log(data.result.rows[0].User.Department.name, "data--res----edit expense--->>>>");
        if (data.result) {
          // setUpdateData(data);
          console.log(data.result, "data.result.state.iddata.result.state.id");

          const taskResources = data.result.TaskResources;

          const { task_document, receipt_name } = data.result;

          const files = task_document?.map((name: string, index: number) => ({
            //  originalName: name,
            originalName: task_document[index],
            // fileType: receipt_file_type[index],
          }));
          setFormValues({
            task_name: data.result.task_name,
            estimated_hours: data.result.estimated_hours,
            actual_hours: data.result.actual_hours,
            status: data.result.status,
          });
          setStartData(dayjs(data.result.start_date));
          setEndDate(dayjs(data.result.end_date));
          setEditorContent(data.result.description);
          setInitialFiles(files);
          setUploadedFiles(files);
          const userIds = taskResources.map(
            (resource: any) => resource.user_id
          );
          setSelectedEmployeeIds(userIds);
          setProjectId(data.result.project_id);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(uploadedFiles, "up files------>>>>>>>>>>>");

  const handleUpdate = () => {
    validate();
    submitChanges();
  };

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateTask(
        id,
        formValues.task_name,
        projectID,
        formValues.estimated_hours,
        formValues.actual_hours,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        editorContent,
        formValues.status,
        uploadedFiles.map((files) => files?.url),
        projectResources
      );

      if (data && data.result) {
        console.log("final data:", data);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          // navigate(`add-task/${projectID}`);
          navigate("/project-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <div className="px-4 pt-6 pb-12">
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate(`/edit-task-details/${projectID}`)}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Task Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate(`/edit-task-details/${projectID}`)}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={() => handleUpdate()}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </button>
        </div>
      </div>
      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white py-4">
        <div className="container mt-10 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Task Name"
                placeholder="Enter Declaration"
                name="task_name"
                value={formValues.task_name}
                onChange={handleFrormChange}
                error={!!errors.task_name}
                helperText={errors.task_name}
              />
              <TextField
                required
                id="outlined-required"
                label="Estimate Hours"
                placeholder="Enter Declaration"
                name="estimated_hours"
                value={formValues.estimated_hours}
                onChange={handleFrormChange}
                error={!!errors.estimated_hours}
                helperText={errors.estimated_hours}
              />
              <TextField
                required
                id="outlined-required"
                label="Actual Hours"
                placeholder="Enter Declaration"
                name="actual_hours"
                value={formValues.actual_hours}
                onChange={handleFrormChange}
                error={!!errors.actual_hours}
                helperText={errors.actual_hours}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartData(newValue)}
                  // className="w-full"
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  //className="w-full"
                />
              </LocalizationProvider>
              <TextField
                required
                id="outlined-required"
                select
                label="Status"
                placeholder="Enter Declaration"
                name="status"
                value={formValues.status}
                onChange={handleFrormChange}
                className="relative"
              >
                {Status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                ))}
              </TextField>
              <div className="col-span-3">
                <TextFieldWithPopup
                  initialSelectedEmployeeIds={selectedEmployeeIds}
                  onSelectedEmployeesChange={handleSelectedEmployeesChange}
                />
              </div>
            </div>

            <div className="mt-6">
              <MyStatefulEditor
                content={editorContent}
                onChange={setEditorContent}
              />
            </div>

            <FileUpload
              existingFiles={initialFiles}
              onFilesChange={handleFilesChange}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditTask;
