import React, { useState, useEffect } from "react";
import profile from "../../assets/Ellipse 580.png";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Chip,
  Grid,
} from "@material-ui/core";
import { useSelector } from "react-redux";

interface Employee {
  firstName: string;
  lastName: string;
  employee_id: string;
  id: number;
  avatar: string;
}

interface TextFieldWithPopupProps {
  onSelectedEmployeesChange: (ids: number[]) => void;
}

const TextFieldWithPopup: React.FC<TextFieldWithPopupProps> = ({
  onSelectedEmployeesChange,
}) => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const access_token = localStorage.getItem("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API}/api/v1/user/list?customer_id=${customer_id}`,
          {
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        const data = await response.json();
        const result: Employee[] = data.result.userList.rows;
        console.log(data, "data userlist----->>>>");

        setEmployees(result);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [customer_id]);

  console.log(employees, "check  emp----->>>>>>>");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (employee: Employee) => {
    setSelectedEmployees((prev) => {
      const updatedSelected = prev.some((emp) => emp.id === employee.id)
        ? prev.filter((emp) => emp.id !== employee.id)
        : [...prev, employee];

      // Call the prop function to update parent state
      onSelectedEmployeesChange(updatedSelected.map((emp) => emp.id));
      return updatedSelected;
    });
  };

  const handleRemove = (id: number) => {
    setSelectedEmployees((prev) => {
      const updatedSelected = prev.filter((emp) => emp.id !== id);

      // Call the prop function to update parent state
      onSelectedEmployeesChange(updatedSelected.map((emp) => emp.id));
      return updatedSelected;
    });
  };

  const handleAssign = () => {
    console.log("Assigned employees:", selectedEmployees);
    handleClose();
  };

  return (
    <div>
      <TextField
        label="Select assigned"
        onClick={handleOpen}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: selectedEmployees.length > 0 && (
            <InputAdornment position="start">
              {selectedEmployees.map((employee) => (
                <Chip
                  key={employee.id}
                  avatar={<Avatar src={employee.avatar} />}
                  label={employee.firstName}
                  onDelete={() => handleRemove(employee.id)}
                  style={{
                    margin: "5px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#49454F",
                  }}
                />
              ))}
            </InputAdornment>
          ),
          endAdornment: (
            // <InputAdornment position="end">
            //   <IconButton onClick={handleOpen}></IconButton>
            // </InputAdornment>
            <></>
          ),
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            width: "500px",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "19.5px",
            lineHeight: "23.4px",
            color: "#1D1A22",
          }}
        >
          Resource list
        </DialogTitle>
        <div className="w-full h-12 bg-[#E1E1E1] flex justify-start items-center px-4 py-3">
          <span className="font-[Poppins] font-medium ext-[14px] leading-[14px] text-[#3F4254]">
            Manager/Employee name
          </span>
        </div>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <List>
              {employees?.map((employee, index) => (
                <>
                  <ListItem
                    key={employee.id}
                    button
                    onClick={() => handleToggle(employee)}
                  >
                    <Grid container alignItems="center">
                      <Grid item>
                        <Checkbox
                          edge="end"
                          checked={selectedEmployees.some(
                            (emp) => emp.id === employee.id
                          )}
                          tabIndex={-1}
                          disableRipple
                          color="primary"
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Grid>
                      <ListItemAvatar>
                        <Avatar
                          src={employee?.avatar || profile}
                          style={{
                            height: "35px",
                            width: "35px",
                            marginLeft: "30px",
                          }}
                        />
                      </ListItemAvatar>
                      <Grid item xs>
                        <ListItemText
                          primary={employee?.firstName + " " + employee?.lastName}
                          secondary={employee?.employee_id}
                          style={{
                            paddingLeft: "20px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            fontSize: "50px",
                            lineHeight: "13.98px",
                            color: "#49454F",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  {/* {index < employees.length - 1 && <Divider variant="inset" />} */}
                </>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions style={{padding: "10px 20px"}}>
          <Button onClick={handleClose} color="primary">
            <button className="borderButton text font-[Poppins] font-semibold text-[14px] leading-[29px]">
              Cancel
            </button>
          </Button>
          <Button onClick={handleAssign} color="primary">
            <button className="buttoncss text-white px-6 py-2.5 rounded-lg font-[Poppins] font-semibold text-[14px] leading-[29px]">
              Assign
            </button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextFieldWithPopup;
