import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";

import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import profile from "../../assets/Ellipse 580.png";

import { fetchEditWFH, approveRejectApi } from "../common/services/WFH";

// !MODAL STYLE
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

// !INTERFACES
interface Approvers {
  approval_id: number;
  approval_status: string;
  role: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  employee_id: string;
  wfh_period: string;
  start_date: string;
  end_date: string;
  reason: string;
}

const ViewWFH = () => {
  // !REDUX DATA
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const userId = loginData?.userId;

  // !VARIABLES
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState<string>("Pending");
  const [loading, setLoading] = useState(false);
  const [approverData, setApproverData] = useState<Approvers[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    employee_id: "",
    wfh_period: "",
    start_date: "",
    end_date: "",
    reason: "",
  });

  // !LOCATION
  const id = location.state?.id;

  // !FUNCTIONS
  const handleApprove = () => {
    approveData();
  };
  const handleReject = () => {
    rejectData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/work-from-home");
  };

  // !API CALLS
  useEffect(() => {
    fetchEditWFH(id)
      .then((data) => {
        console.log(data.result, "data--res----edit--->>>>");
        if (data.result) {
          console.log(data.result, "data.result----->>>>>wfh-view-->>>");
          setFormValues({
            firstName: data.result.User.firstName,
            lastName: data.result.User.lastName,
            employee_id: data.result.User.employee_id,
            wfh_period: data.result.wfh_session,
            start_date: data.result.from_date,
            end_date: data.result.to_date,
            reason: data.result.reason,
          });
          setApproverData(data.result?.WfhRequestApprovals);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [id]);

  const approveData = async () => {
    const status = "Approved";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response.data?.message);
      setTimeout(() => {
        navigate("/work-from-home");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const rejectData = async () => {
    const status = "Rejected";
    try {
      const response = await approveRejectApi(id, status);
      toast.success(response.data?.message);
      setTimeout(() => {
        navigate("/work-from-home");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  // !APPROVERS
  useEffect(() => {
    approverData?.map((approver) => {
      if (approver?.approval_id === userId) {
        setShowButtons(true);
        setStatus(approver.approval_status);
      }
    });
  }, [approverData]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Employee WFH details Approved successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-6 px-4 pb-12">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-start h-12 justify-between">
            <div className="flex flex-row gap-[13px] items-center">
              <button onClick={() => navigate("/work-from-home")}>
                <img
                  src={right}
                  alt="right arrow sign"
                  className="h-6 w-6 shrink-0 bg-black rounded-full"
                />
              </button>
              <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
                Work From Home Details
              </span>
            </div>

            {showButtons ? (
              <div className="flex flex-start gap-5 h-full">
                {status !== "Rejected" ? (
                  <button
                    onClick={handleReject}
                    className="flex w-[148px] bg-[#B3261E] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg h-12 hover:bg-[#96150e]"
                  >
                    <div className="text-[#fff] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      Reject
                    </div>
                  </button>
                ) : (
                  <></>
                )}
                {status !== "Approved" ? (
                  <button
                    className="flex w-[138px] p-[13.18px,21px,14.82px,21px] bg-[#359349] justify-center items-center flex-[1,1,0] self-stretch rounded-lg text-white h-12 hover:bg-[#1f8735]"
                    onClick={handleApprove}
                  >
                    <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                      Approve
                    </div>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="p-5 bg-white rounded-lg shadow-md flex flex-col gap-5">
            <span className="text-lg font-semibold font-[Inter] h-6 text-black">
              Details
            </span>
            <div
              className="bg-gradient-to-r rounded-md py-5 px-2.5"
              style={{
                background:
                  "linear-gradient(90deg, rgba(65, 101, 196, 0.05) 0%, rgba(142, 31, 210, 0.05) 100%)",
              }}
            >
              <div className="flex flex-col gap-[15px]">
                <div className="grid grid-cols-2">
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Employee Name & ID
                    </span>
                    <div className="flex items-center gap-[13px] h-8">
                      <img src={profile} alt="profile" className="h-8 w-8" />
                      <div className="flex flex-col">
                        <span className="font-[Poppins] font-medium text-base text-[#1D1A22]">
                          {formValues?.firstName} {formValues?.lastName}
                        </span>
                        <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          {formValues.employee_id}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      WFH Period
                    </span>
                    <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                      {formValues.wfh_period}
                    </span>
                  </div>
                </div>
                <hr className="border-b-[2px]" />
                <div className="grid grid-cols-2">
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Start Date
                    </span>
                    <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                      {formValues.start_date}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      End Date
                    </span>
                    <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                      {formValues.end_date}
                    </span>
                  </div>
                </div>
                <hr className="border-b-[2px]" />
                <div className="grid grid-cols-1">
                  <div className="flex flex-col gap-[7px]">
                    <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                      Reason
                    </span>
                    <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                      {formValues.reason}
                    </span>
                  </div>
                </div>
                <hr className="border-b-[2px]" />
                <div className="flex flex-col gap-[7px]">
                  <span className="font-[Poppins] font-normal text-base text-[#99A1B7]">
                    Approval Status
                  </span>
                  <div className="grid grid-cols-2">
                    {approverData?.map((approver) => (
                      <span className="font-[Inter] font-medium text-[16px] leading-[19.36px] text-[#1D1A22]">
                        {approver.role} -{" "}
                        <span
                          className={`p-1 rounded-sm ${
                            approver.approval_status === "Approved"
                              ? "bg-[#D4F8C7]"
                              : approver.approval_status === "Pending"
                              ? "bg-[#FFC0767A]"
                              : approver.approval_status === "Rejected"
                              ? "bg-[#F8716A7A]"
                              : ""
                          }`}
                        >
                          {approver.approval_status}
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewWFH;
