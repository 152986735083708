import React, { useEffect, useState } from "react";
import right from "../../../image/chevron-right.png";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import check from "../../../image/checkmark-circle-02.svg";
import { fetchEditHoliday, updateHoliday } from "../../common/services/Holiday";
import axios from "axios";
import toast from "react-hot-toast";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  description: string;
  holiday_name: string;
}

const EditHoliday = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    validate();
    submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/holiday");
  };

  const [formValues, setFormValues] = useState<FormValues>({
    description: "",
    holiday_name: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({})

  const validate = (): boolean => {
    let temperrors: Partial<FormValues> = {}

    if(formValues.holiday_name === "")
      temperrors.holiday_name = "Holiday Name is required"
    if(formValues.description === "")
      temperrors.description = "Description is required"

    setErrors(temperrors)
    return Object.keys(temperrors).length === 0;
  }

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    // setLoading(true);
    console.log("edit check");
    fetchEditHoliday(id)
      .then((data) => {
        console.log(data.result, "data--res----edit--->>>>");
        if (data.result) {
          // setUpdateData(data);
          console.log(data.result, "data.result----->>>>>holiday--->>>");

          setFormValues({
            description: data.result.description,
            holiday_name: data.result.holiday_name,
          });
          setStartDate(dayjs(data.result.holiday_date));

        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateHoliday(
        id,
        formValues.holiday_name,
        formValues.description,
        dayjs(startDate).format("YYYY-MM-DD")
      );

      if (data && data.result) {
        console.log("final data:", data);
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/holiday");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Holiday details updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="bg-[#FBFBFB] py-8 px-[18.5px] h-full">
        <div className="flex px-4 flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/holiday")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Holiday Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/holiday")}
              className="flex w-[148px] border border-solid borderButton p-[13.18px,50px,14.82px,51px]  justify-center items-center self-stretch rounded-lg h-12"
              //onClick={(event) => handleCurrentPageChange("table", event)}
            >
              <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
              //   onClick={() => handleCurrentPageChange("addNew")}
            >
              <div
                onClick={handleOpen}
                className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              >
                Update
              </div>
            </button>
          </div>
        </div>

        <div className="rounded overflow-hidden shadow-lg bg-white m-4">
          <div className="container mt-2 mx-auto p-4">
            <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">
              Holiday details
            </div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="grid grid-cols-3 mt-4 gap-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      className="w-full"
                      minDate={dayjs()}
                    />
                </LocalizationProvider>
                <TextField
                  required
                  id="outlined-required"
                  label="Holiday"
                  defaultValue=" "
                  name="holiday_name"
                  value={formValues.holiday_name}
                  onChange={handleFrormChange}
                  error={!!errors.holiday_name}
                  helperText={errors.holiday_name}
                />
              </div>

              <div className="grid grid-cols-1">
                <TextField
                  required
                  id="outlined-required"
                  label="Description"
                  defaultValue=" "
                  multiline
                  rows="3"
                  name="description"
                  value={formValues.description}
                  onChange={handleFrormChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHoliday;
