// import React from 'react'

// const EditAttendence = () => {
//   return (
//     <div>EditAttendence</div>
//   )
// }

// export default EditAttendence

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import check from "../../image/checkmark-circle-02.svg";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import {
  Fetch_Edit_Attendence_List,
  updateAttendence,
} from "../common/services/Attendence";
import { useSelector } from "react-redux";
import EmployeeSearch from "../EmployeeManagement/EmployeeSearch";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import axios from "axios";
import toast from "react-hot-toast";
import { Attendence_Unit } from "../../redux/actions/Attendence/Attendence";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const EditAttendence = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const attendenceData = useSelector(
    (state: any) => state.attendence.AttendenceEditData
  );
  console.log(attendenceData, "attendenceDataa----->>>>edit");

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [empId, setEmpid] = useState<string>();
  const [checkIn, setCheckIn] = React.useState<Dayjs | null>(null);
  const [checkOut, setCheckOut] = React.useState<Dayjs | null>(null);
  const [date, setDate] = React.useState<string>();

  const dispatch = useDispatch();

  const handleOpen = () => {
    checkIn?.isBefore(checkOut)
      ? submitChanges()
      : alert("CheckIn time cannot br after CheckOut time");
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/attendance-management");
  };
  const handleCancel = () => {
    navigate("/attendance-management");
  };
  const { api } = useParams();
  const location = useLocation();
  const id = location.state?.id;
  console.log(id, "ide----->>>>>");
  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  useEffect(() => {
    dispatch<any>(Attendence_Unit(customer_id, navigate, id));
  }, []);

  // const fetchData = async () => {
  //   // setLoading(true);
  //   try {
  //     const response = await Fetch_Edit_Attendence_List(
  //       customer_id,
  //       id
  //       //  numOfItems,
  //       // page,
  //       //sortType,
  //       //searchTerm
  //       // startDate,
  //       // endDate
  //     );
  //     if (response.status === 200) {
  //       console.log("Data: edit attendence---->>>>>", response.data);
  //       setEmpid(response.data.result[0].User.employee_id);
  //       setCheckIn(dayjs(response.data.result[0].start_time));
  //       setCheckOut(dayjs(response.data.result[0].end_time));
  //       // setTableData(response.data.result.userData.rows);
  //       //setCount(response.data.result.count);
  //     } else {
  //       console.error("Error:", response.status, response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  //   //setLoading(false);
  // };

  useEffect(() => {
    if (attendenceData) {
      setEmpid(attendenceData?.User?.employee_id);
      setCheckIn(dayjs(attendenceData?.start_time));
      setCheckOut(dayjs(attendenceData?.end_time));
    }
  }, [attendenceData]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    // console.log("Request body:", body); // Print the body

    setLoading(true);
    try {
      const data = await updateAttendence(id, checkIn, checkOut);

      if (data && data.result == "") {
        console.log("final data:", data);
        //toast.success(data.message)
        setOpen(true);

        setTimeout(() => {
          setOpen(false);
          navigate("/attendance-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  const handleCheckInChange = (newValue: Dayjs | null) => {
    setCheckIn(newValue);
    setDate(newValue?.format("YYYY-MM-DD"));
  };

  const handleCheckOutChange = (newValue: Dayjs | null) => {
    setCheckOut(newValue);
  };

  return (
    <div className="p-6">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Attendence Updated successfully!
          </Typography>
        </Box>
      </Modal>

      <div className="flex flex-row items-center px-4 h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/attendance-management")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Edit Employee Attendance
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/attendance-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Update
            </div>
          </button>
        </div>
      </div>

      <div className="rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          {/* <div className="text-[#262626] font-[500] text-[14px] ml-4 font-['Poppins']">Sick Leave Details</div> */}
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 h-[300px] mt-4 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Employee Code"
                name="employeeCode"
                defaultValue=" "
                disabled
                value={empId}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="CheckIn Time"
                  value={checkIn}
                  onChange={(e) => handleCheckInChange(e)}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="CheckOut Time"
                  views={["year", "month", "day", "hours", "minutes"]}
                  value={checkOut}
                  onChange={handleCheckOutChange}
                />
              </LocalizationProvider>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditAttendence;
