import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { Fetch_Attendence_List, Fetch_Edit_Attendence_List_List } from "../../../components/common/services/Attendence";
import { AttendanceActionData } from "../../reducers/AttendenceSlice/AttendenceSlice";


const handleErrorResponse = (status: number, navigate: NavigateFunction) => {
  if ([401, 402, 403, 405, 500].includes(status)) {
    navigate("/server-error");
    return true;
  }
  return false;
};

export const Fetch_Attendence_List_List_Management = (
  customer_id: number,
  numOfItems: number,
  page: number,
  searchTerm: string,
  startDate: Date | null,
  endDate: Date | null,
  group_id:number,
  department_id:number,
) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      console.log("gyughbhgbhjbv ======");

      const response = await Fetch_Attendence_List(
        customer_id,
        numOfItems,
        page,
        group_id,
        department_id,
        searchTerm,
        startDate,
        endDate,
      );
      console.log(response, "response tattendencs data------>>>>>");

      //const data = await response.json();
      if (response.status !== 200) {
        throw new Error("Could not fetch service data!");
      }
      return response;
    };

    try {
      console.log("before tryyyy----->>>>");
      const resData = await fetchData();
      const AttendenceData = resData?.data;
      console.log(AttendenceData, "AttendenceData==========>>>>>>");
      dispatch(AttendanceActionData.setAttendenceData(AttendenceData || []));
    } catch (error) {
      if (error instanceof Error) {
        console.log("Fetching Service data failed!", error);
      } else {
        console.log("An unknown error occurred:", error);
      }
    }
  };
};


export const Attendence_Unit =
  (customer_id: number, navigate: NavigateFunction, target_id: number) =>
  async (dispatch: Dispatch) => {
    console.log("customer_id", customer_id);
    try {
      const response = await Fetch_Edit_Attendence_List_List(customer_id);
      console.log("responsive department-----che>>>>", response);
      if (!response.ok) {
        if (handleErrorResponse(response.status, navigate)) return;
        throw new Error("Could not fetch Ticket history data!");
      }
      const data = await response.json();
      console.log("Fetched data:", data);

      // Apply the find method to filter data by target_id
      const foundItem = data?.result?.rows?.find((item: { id: number }) => item?.id === target_id);
      if (!foundItem) {
        throw new Error(`Item with id ${target_id} not found`);
      }

      console.log("Found item:Attendence", foundItem);

      // Dispatch the found item
      dispatch(AttendanceActionData.setEditAttendenceData(foundItem));
      return foundItem;
    } catch (error) {
      console.error("Error fetching history data:", error);
      throw error;
    }
  };
