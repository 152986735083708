import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { createExpenseType } from "../common/services/ExpenseType";
import toast from "react-hot-toast";


interface FormValues{
  expense_category_name:string,
}


const AddClient = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    validate();
    postData();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/clients");
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/clients");
  }

  const [formValues, setFormValues] = useState<FormValues>({
    expense_category_name:"",
  });
  
  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};
    if(formValues.expense_category_name === "")
      tempErrors.expense_category_name = "Declaration Type is required"

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const postData = async () => {
    // setLoading(true);
    try {
      const response = await createExpenseType(
        formValues.expense_category_name
      );
      // console.log(response,"respost--->>>");

      setOpen(true);

      // dispatch(
      //   userManagementActions.resetUserRoleData({
      //     count: 0,
      //     rows: [],
      //     currentPage: 1,
      //     itemsPerPage: 10,
      //     isNext: true,
      //   })
      // );

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate("/clients");
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //setLoading(false);
  };

  return (
    <div className="mt-8">
      <div className="flex flex-row items-center px-4 h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button onClick={() => navigate("/clients")}>
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Expense Type Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={handleCancel}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChange("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={handleOpen}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white m-4">
        <div className="container mt-10 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 gap-4">
              <TextField
                required
                id="outlined-required"
                label="Expense Type"
                placeholder="Enter Expense Type"
                name="expense_category_name"
                value={formValues.expense_category_name}
                onChange={handleFrormChange}
                error={!!errors.expense_category_name}
                helperText={errors.expense_category_name}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
