import React, { useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import MyStatefulEditor from "./Editor";
import FileUpload from "../ExpenseManagement/FileUpload";
import { MenuItem } from "@mui/material";
import { Fetch_Clients_List_Data } from "../common/services/Clients";
import { useSelector } from "react-redux";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { createProjectsApi } from "../common/services/Project";
import toast from "react-hot-toast";

interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
}

interface FormValues {
  project_name: string;
  status: string;
  currency: string;
  client: string;
  Estimate_cost: string;
  Actual_cost: string;
  start_date: string;
  end_date: string;
  estimate_hours: number;
  actual_hours: number;
  project_type: string;
}

interface Leave_Type {
  id: string;
  client_name: string;
}

const AddProjectDetails = () => {
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState<Leave_Type[]>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [startDate, setStartData] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    console.log("Files updated:", files);
  };

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
  }

  console.log(uploadedFiles, "up--->>>");

  const [formValues, setFormValues] = useState<FormValues>({
    project_name: "",
    status: "",
    currency: "",
    client: "",
    Estimate_cost: "",
    Actual_cost: "",
    start_date: "",
    end_date: "",
    estimate_hours: 0,
    actual_hours: 0,
    project_type: "",
  });

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const validate = (): boolean => {
    let tempErrors: Partial<FormValues> = {};

    if (formValues.project_name === "")
      tempErrors.project_name = "Project Name is required";
    if (formValues.status === "")
      tempErrors.status = "Project Status is required";
    if (formValues.currency === "")
      tempErrors.currency = "Currency is required";
    if (formValues.client === "") tempErrors.client = "Client Name is required";
    if (formValues.Estimate_cost === "")
      tempErrors.Estimate_cost = "Estimated Cost is required";
    if (formValues.Actual_cost === "")
      tempErrors.Actual_cost = "Actual Cost is required";
    if (formValues.estimate_hours === 0) tempErrors.estimate_hours = 1;
    if (formValues.actual_hours === 0) tempErrors.actual_hours = 1;
    if (formValues.project_type === "")
      tempErrors.project_type = "Project Type is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFrormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (value !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const Currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
  ];

  const Status = [
    { value: "upcoming", label: "Up Coming" },
    { value: "ongoing", label: "On Going" },
    { value: "completed", label: "Completed" },
    { value: "onhold", label: "On Hold" },
  ];

  const fetchLeaveType = async () => {
    //  setLoading(true);
    try {
      const response = await Fetch_Clients_List_Data(
        customer_id,
        // searchTerm,
        numOfItems,
        page
      );

      if (response.status === 200) {
        console.log("Data: clients Tpye", response.data.result);
        setTableData(response.data.result.rows);

        // setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    //setLoading(false);
  };

  useEffect(() => {
    fetchLeaveType();
  }, [page, numOfItems]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, project_type: e.target.value });
    // if (e.target.value !== "") {
    //   setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    // }
  };

  console.log(editorContent, "editorContent---->>>>>");
  const { param } = useParams();
  console.log(param, "param----->>>>>>>");

  const postData = async () => {
    setLoading(true);
    try {
      const response = await createProjectsApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),

        formValues.project_name,
        formValues.currency,
        formValues.client,
        formValues.Estimate_cost,
        formValues.Actual_cost,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        Number(formValues.estimate_hours),
        Number(formValues.actual_hours),
        formValues.status,
        uploadedFiles.map((files) => files.url),
        formValues.project_type,
        editorContent
      );
      console.log(response.data.result.id, "respost--->>>");
      const projectId = response.data.result.id;

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate(`/add-resources/${projectId}`, {
          state: { id: projectId },
        });
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  console.log(
    formValues.estimate_hours,
    formValues.actual_hours,
    "gvbgvgghvg---->>>>>>>>."
  );

  const [isStartDate, setIsStartDate] = useState<boolean | null>(false);
  const [isStartDateDisplay, setIsStartDateDisplay] = useState<boolean | null>(
    false
  );
  const [isJoiningDate, setIsJoiningDate] = useState<boolean | null>(false);
  const [isJoiningDateDisplay, setIsJoiningDateDisplay] = useState<
    boolean | null
  >(false);

  const handleOpen = () => {
    !isStartDate && setIsStartDateDisplay(true);
    !isJoiningDate && setIsJoiningDateDisplay(true);
    validate();
    postData();
    //navigate(`/add-resources/8`);
  };

  return (
    <div>
      <div className="flex flex-row items-center h-12 justify-between">
        <div className="flex flex-row gap-[13px] justify-center items-center">
          <button
            onClick={() => navigate("/project-management")}
            //  onClick={() => navigate("/client")}
          >
            <img
              src={right}
              alt="right arrow sign"
              className="h-6 w-6 shrink-0 bg-black rounded-full"
            />
          </button>
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Add Project Details
          </span>
        </div>
        <div className="flex flex-start gap-5">
          <button
            onClick={() => navigate("/project-management")}
            className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center rounded-lg self-stretch borderButton h-12"
            //onClick={(event) => handleCurrentPageChang  e("table", event)}
          >
            <div className="text text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
              Cancel
            </div>
          </button>
          <button
            type="submit"
            form="form"
            className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            onClick={() => handleOpen()}
          >
            <div
              // onClick={handleOpen}
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
            >
              Continue
            </div>
          </button>
        </div>
      </div>

      <div className="mt-6 rounded overflow-hidden shadow-lg bg-white">
        <div className="container mt-10 mx-auto p-4">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="grid grid-cols-3 ga p-4">
              <TextField
                required
                id="outlined-required"
                label="Project Name"
                placeholder="Enter Project"
                value={formValues.project_name}
                name="project_name"
                onChange={handleFrormChange}
                error={!!errors.project_name}
                helperText={errors.project_name}
              />
              <TextField
                required
                id="outlined-required"
                label="Status"
                select
                value={formValues.status}
                onChange={handleFrormChange}
                placeholder="Status"
                name="status"
                error={!!errors.status}
                helperText={errors.status}
              >
                {Status?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Currency"
                select
                // placeholder="Enter Declaration"
                value={formValues.currency}
                onChange={handleFrormChange}
                name="currency"
                error={!!errors.currency}
                helperText={errors.currency}
              >
                {Currency.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Client"
                select
                value={formValues.client}
                onChange={handleFrormChange}
                placeholder="Select Client"
                name="client"
                error={!!errors.client}
                helperText={errors.client}
              >
                {tableData?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.client_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-required"
                label="Estimate Cost"
                placeholder="Enter Estimated Cost"
                value={formValues.Estimate_cost}
                onChange={handleFrormChange}
                name="Estimate_cost"
                error={!!errors.Estimate_cost}
                helperText={errors.Estimate_cost}
              />
              <TextField
                required
                id="outlined-required"
                label="Actual Cost"
                placeholder="Enter Actual Cost"
                name="Actual_cost"
                value={formValues.Actual_cost}
                onChange={handleFrormChange}
                error={!!errors.Actual_cost}
                helperText={errors.Actual_cost}
              />
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartData(newValue);
                      setIsStartDate(true);
                      setIsStartDateDisplay(false);
                    }}
                    className="w-full"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isStartDateDisplay ? "red" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isStartDateDisplay ? "red" : "",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: isStartDateDisplay ? "red" : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: isStartDateDisplay ? "red" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        color: isStartDateDisplay ? "red" : "",
                      },
                    }}
                  />
                </LocalizationProvider>
                {isStartDateDisplay && (
                  <span className="px-4 text-red-600 text-xs font-medium">
                    start Date is required
                  </span>
                )}
              </div>
              <div className="flex flex-col items-start w-full pr-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                      setIsJoiningDate(true);
                      setIsJoiningDateDisplay(false);
                    }}
                    className="w-full"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: isJoiningDateDisplay ? "red" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: isJoiningDateDisplay ? "red" : "",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: isJoiningDateDisplay ? "red" : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: isJoiningDateDisplay ? "red" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        color: isJoiningDateDisplay ? "red" : "",
                      },
                    }}
                  />
                </LocalizationProvider>
                {isJoiningDateDisplay && (
                  <span className="px-4 text-red-600 text-xs font-medium">
                    End Date is required
                  </span>
                )}
              </div>
              <TextField
                required
                id="outlined-required"
                label="Estimate Hours"
                placeholder="Enter estimated Hours"
                value={formValues.estimate_hours}
                onChange={handleFrormChange}
                name="estimate_hours"
                error={!!errors.estimate_hours}
                helperText={
                  errors.estimate_hours === 1 && "Estimate Hours are required"
                }
              />
              <TextField
                required
                id="outlined-required"
                label="Actual Hours"
                placeholder="Enter Actual Hours"
                name="actual_hours"
                value={formValues.actual_hours}
                onChange={handleFrormChange}
                error={!!errors.actual_hours}
                helperText={
                  errors.actual_hours === 1 && "Actual Hours are required"
                }
              />
            </div>

            <div className="mt-4 pl-2">
              <FormControl error={!!errors.project_type}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Project Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formValues.project_type}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="Billable"
                    control={<Radio />}
                    label="Billable"
                  />
                  <FormControlLabel
                    value="Non Billable"
                    control={<Radio />}
                    label="Non Billable"
                  />
                  <FormControlLabel
                    value="Revenue Generation"
                    control={<Radio />}
                    label="Revenue Generation"
                  />
                </RadioGroup>
              </FormControl>
              <FormHelperText>
                <span className="text-[#d32f2f]">{errors.project_type}</span>
              </FormHelperText>
            </div>

            <div className="mt-6">
              <MyStatefulEditor onChange={setEditorContent} />
            </div>

            <FileUpload onFilesChange={handleFilesChange} />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddProjectDetails;
