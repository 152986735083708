import React, { useCallback, useEffect, useState } from "react";
import right from "../../image/chevron-right.png";
import search from "../../assets/Search.svg";
import eye from "../../image/44 Visibility.png";
import edit from "../../assets/Crud_Icons/edit.svg";
import profile from "../../assets/Ellipse 580.png";
import TablePaginationDemo from "../common/Pagenation";
import { useNavigate } from "react-router-dom";
import download from "../../image/Vector (1).png";
import date from "../../assets/date.svg";
import DatePicker from "react-datepicker";
import { createGlobalStyle } from "styled-components";
import SelectDropdown2 from "../EmployeeManagement/SelectDropDown2";
import upload from "../../assets/plus 1.png";
import plus from "../../assets/up-loading 1.png";
import { useDispatch } from "react-redux";
import { Fetch_Attendence_List_List_Management } from "../../redux/actions/Attendence/Attendence";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { debounce } from "lodash";
import {
  Fetch_Attendence_List,
  Fetch_Attendence_List1,
} from "../common/services/Attendence";
import { AttendanceActionData } from "../../redux/reducers/AttendenceSlice/AttendenceSlice";
import {
  Department_Unit,
  EmployeeUnit,
} from "../../redux/actions/Employee/Employee";
import maskPlus from "../../image/Mask group.svg";
import SelectDropdown from "../EmployeeManagement/SelectDropDown";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import clock from "../../assets/clock.svg";
import location from "../../assets/location.svg";
import filter from "../../assets/filter.svg";
import Search from "../SearchBar/Search";
import Filter from "../Filter/Filter";
import { Chip } from "@material-ui/core";
import * as XLSX from "xlsx";

dayjs.extend(duration);

const GlobalStyle = createGlobalStyle`
  .react-datepicker-wrapper {
    /* Add your styles here */
    width: 100%;
  }

  .react-datepicker__input-container {
    /* Add your styles here */
    /* width: calc(100% + 28px); */
    padding-left: 5px;
  }

  .react-datepicker__input-container > input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
`;

interface RowData {
  User: {
    firstName: string;
    lastName: string;
    employee_id: string;
  };
  start_time: string;
  check_in_address: string;
  end_time: string;
  check_out_address: string;
  id: number;
  mode_of_work: string;
  // createdByUser: createdByUser;
}
interface Items {
  start_time: string;
  end_time: string;
  // Add other necessary fields
}

interface Business_Unit {
  createdAt: string;
  customer_id: Number;
  deletedAt: null | string;
  id: Number;
  name: string;
  parent_id: Number;
  spoc_id: Number;
  status: string;
  updatedAt: string;
  user_id: null | string;
}

interface Department_Unit {
  createdAt: string;
  createdby: string | null;
  customer_id: Number;
  deletedAt: string | null;
  group_id: Number;
  id: Number;
  is_active: boolean;
  modifiedby: string | null;
  name: string;
  parent_id: string | null;
  spoc_id: Number;
  status: string;
  updatedAt: string;
}

interface Column {
  id: string;
  displayName: string;
}

interface EmployeeData {
  [key: string]: any; // Adjust this based on your data structure
}

const AttendenceDetails = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const attendenceData = useSelector(
    (state: any) => state.attendence.AttendenceData
  );

  const [startSession, setStartSession] = useState("");
  const [endSession, setEndSession] = useState("");

  const startDate = useSelector((state: any) => state.attendence.startDate);

  const endDate = useSelector((state: any) => state.attendence.endDate);
  console.log(attendenceData, "attendenceData- start-------?>>>>>>>");

  let customer_id = 0;
  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    customer_id = loginData.customer_id;
    console.log(loginData.customer_id, "loginData.customer_id--->>>>");
  }

  const [selectedDates, setSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [tempSelectedDates, setTempSelectedDates] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [count, setCount] = useState(0);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [bussness, setBusiness] = useState<Business_Unit[]>();
  const [dateRange, setDateRange] = useState(false);
  const [departmentSelect, setDepartmentSelect] = useState(false);
  const [tableData1, setTableData1] = useState<EmployeeData[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [columns, setColumns] = useState<Column[]>([]);
  const [businessSelect, setBusinessSelect] = useState(false);
  const [department, setDepartment] = useState<Department_Unit[]>();
  const [selectedDepartmentUnit, setSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedBusinessUnit, setTempSelectedBusinessUnit] = useState<
    Business_Unit | Department_Unit
  >();
  const [tempSelectedDepartmentUnit, setTempSelectedDepartmentUnit] = useState<
    Business_Unit | Department_Unit
  >();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const crmType = "Attendence Details";
  const addType = "Add Employee Attendence";

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Attendence_List(
        customer_id,
        numOfItems,
        page,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id),
        //sortType,
        searchTerm,
        startDate,
        endDate
      );
      if (response.status === 200) {
        console.log("Data: designation attt----...", response.data);

        // const dataWithDurations = response.data.result.map((item: Items) => {
        //   const startDateTime = dayjs(item.start_time);
        //   const endDateTime = dayjs(item.end_time);
        //   const duration = dayjs.duration(endDateTime.diff(startDateTime));

        //   const days = duration.days();
        //   const hours = duration.hours();
        //   const minutes = duration.minutes();

        //   const durationString = `${days} days, ${hours} hours, ${minutes} minutes`;

        //   return {
        //     ...item,
        //     duration: durationString,
        //   };
        // });

        const dataWithDurations = response.data.result?.rows?.map(
          (item: Items) => {
            const startDateTime = dayjs(item.start_time);
            const endDateTime = dayjs(item.end_time);
            const duration = dayjs.duration(endDateTime.diff(startDateTime));

            // Calculate the total duration in hours and minutes
            const totalMinutes = Math.floor(duration.asMinutes());
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            // Format hours and minutes to ensure two digits
            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");

            const durationString = `${formattedHours}:${formattedMinutes}`;

            return {
              ...item,
              duration: durationString,
            };
          }
        );

        setTableData(dataWithDurations);

        setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const fetchData1 = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Attendence_List1(customer_id);
      if (response.status === 200) {
        console.log("Data: designation attt----...", response.data);

        const fetchedData: EmployeeData[] = response.data?.result?.rows;
        setTableData1(fetchedData);

        const columnMapping: { [key: string]: string } = {
          id: "ID",
          name: "Name",
          designation: "Designation",
          department: "Department",
          employee_code: "Employee Code",
          // Add other mappings here
        };

        const cols: Column[] = Object.keys(fetchedData[0]).map((key) => ({
          id: key,
          displayName:
            columnMapping[key] || key.charAt(0)?.toUpperCase() + key?.slice(1),
        }));
        setColumns(cols);

        setDataFetched(true);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData1();
  }, []);

  const handleDownload = () => {
    if (dataFetched) {
      // Generate worksheet data from tableData1
      const worksheetData = [
        columns.map((col) => col.displayName), // Header row
        ...tableData1.map((row) =>
          columns.map((col) => row[col.id]?.toString() || "")
        ), // Data rows
      ];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Data");

      // Generate a binary string representing the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element, set its download attribute, and click it programmatically
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "attendence.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } else {
      console.warn("Data has not been fetched yet.");
    }
  };

  console.log(selectedDepartmentUnit, selectedBusinessUnit);

  console.log(tableData, "tableData---------->>>>>>>check_out_address");

  const handleBusinessUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedBusinessUnit(option);
    console.log("Selected Business Unit:", option);
  };

  const handleDepartmentUnitSelect = (
    option: Business_Unit | Department_Unit
  ) => {
    setTempSelectedDepartmentUnit(option);
    console.log("Selected Department Unit:", tempSelectedDepartmentUnit);
  };

  useEffect(() => {
    fetchData();
    console.log("after fetch page", page);
  }, [
    page,
    numOfItems,
    searchTerm,
    startDate,
    endDate,
    selectedBusinessUnit?.id,
    selectedDepartmentUnit?.id,
    startSession,
    endSession,
  ]);

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === "start_session" || event.key === "end_session") {
      // Use empty string as default value if localStorage returns null
      const start = localStorage.getItem("start_session") || "";
      const end = localStorage.getItem("end_session") || "";

      setStartSession(start);
      setEndSession(end);
    }
  };

  useEffect(() => {
    // Initialize state with values from localStorage
    const start = localStorage.getItem("start_session") || "";
    const end = localStorage.getItem("end_session") || "";
    setStartSession(start);
    setEndSession(end);

    // Add event listener to handle storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  console.log(startSession, "startSessionstartSession---->>>>>");

  useEffect(() => {
    dispatch<any>(
      Fetch_Attendence_List_List_Management(
        customer_id,
        numOfItems,
        page,
        searchTerm,
        startDate,
        endDate,
        Number(selectedBusinessUnit?.id),
        Number(selectedDepartmentUnit?.id)
      )
    );
  }, []);

  function handleNumberOfPages(value: number) {
    setNumOfItems(value);
  }
  function handlePageChange(value: number) {
    setPage(value);
  }

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setSelectedDates(dates);
    // dispatch(AttendanceActionData.setStartDate(dates[0]));
    // dispatch(AttendanceActionData.setEndDate(dates[1]));
  };

  const updateSearchTerm = useCallback(
    debounce((event: any) => {
      setSearchTerm(event.target.value);
    }, 1000), // delay in ms
    [setSearchTerm]
  );

  useEffect(() => {
    console.log("chek print");

    const fetchHistory = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          EmployeeUnit(customer_id, navigate)
        );
        console.log(historyData, "historyData---->>>");

        if (historyData) {
          const recentThree = historyData?.result;
          setBusiness(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchHistory();
  }, []);

  useEffect(() => {
    console.log("chek print");

    const fetchDepartment = async () => {
      console.log("after print");

      try {
        const historyData = await dispatch<any>(
          Department_Unit(customer_id, navigate)
        );
        console.log(historyData, "historyData department---->>>");

        if (historyData) {
          const recentThree = historyData?.result.rows;
          setDepartment(recentThree);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
      }
    };

    fetchDepartment();
  }, []);

  const handleCalanderClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleFilterApply = () => {
    const [startDate, endDate] = selectedDates;

    setTempSelectedDates(selectedDates);

    setSelectedBusinessUnit(tempSelectedBusinessUnit);
    setSelectedDepartmentUnit(tempSelectedDepartmentUnit);
    dispatch(AttendanceActionData.setStartDate(startDate));
    dispatch(AttendanceActionData.setEndDate(endDate));
    if (selectedDates[0] !== null || selectedDates[1] !== null) {
      setDateRange(true);
    }
    tempSelectedDepartmentUnit && setDepartmentSelect(true);
    tempSelectedBusinessUnit && setBusinessSelect(true);
    setIsOpen(false);
    setSelectedDates([null, null]);
  };

  const handleDeleteDate = () => {
    setDateRange(false);
    dispatch(AttendanceActionData.setStartDate(null));
    dispatch(AttendanceActionData.setEndDate(null));
    setTempSelectedDates([null, null]);
    fetchData();
  };
  const handleDeleteDepartment = () => {
    setDepartmentSelect(false);
    setTempSelectedDepartmentUnit(undefined);
    setSelectedDepartmentUnit(undefined);
    fetchData();
  };
  const handleDeleteBusiness = () => {
    setBusinessSelect(false);
    setTempSelectedBusinessUnit(undefined);
    setSelectedBusinessUnit(undefined);
    fetchData();
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  return (
    <div className="px-4 pt-6 pb-12">
      <div className="flex justify-between">
        <div className="flex flex-row gap-[2px] justify-center items-center">
          <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
            Attendence Details
          </span>
        </div>
        <div>
          <button
            onClick={() => navigate("/attendance-management/add-attendence")}
            className="flex items-center font-['Poppins'] py-2 font-[600] text-[14px] px-4 buttoncss text-white rounded-lg"
          >
            <img className="w-5 h-5 mr-2" src={maskPlus} alt="plus icon" />
            Add Attendence Details
          </button>
        </div>
      </div>

      <div className="mt-6">
        <div className="h-[40px]">
          <div className="h-full flex justify-between items-center">
            <Search
              onChange={updateSearchTerm}
              label="Search with employee name"
            />
            <div className="h-full flex items-center gap-5">
              <div className="h-full flex items-center gap-2">
                {dateRange && (
                  <Chip
                    label="Date Range"
                    onDelete={handleDeleteDate}
                    size="small"
                  />
                )}
                {departmentSelect && (
                  <Chip
                    label={selectedDepartmentUnit?.name}
                    onDelete={handleDeleteDepartment}
                    size="small"
                  />
                )}
                {businessSelect && (
                  <Chip
                    label={selectedBusinessUnit?.name}
                    onDelete={handleDeleteBusiness}
                    size="small"
                  />
                )}
              </div>
              <button
                className="h-full w-28 flex justify-evenly items-center border-2 border-[#CDCDCD] rounded-lg bg-white"
                onClick={() => setIsOpen(true)}
              >
                <img src={filter} alt="filter" className="h-3.5 w-3.5" />
                <span className="font-[Poppins] font-semibold text-[14px] leading-[29px] text-[#49454F]">
                  Filter
                </span>
              </button>
              <div className="h-full">
                <button
                  onClick={handleDownload}
                  className="h-full w-full px-[19px] py-[5.5px] flex gap-[7px] items-center borderButton text"
                >
                  <img
                    src={download}
                    alt="download"
                    className="h-[15px] w-[15px]"
                  />
                  <span className="font-[Poppins] font-semibold text-[14px] leading-[29px]">
                    Download Report
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="w-full min-w-max text-left table-auto border-[0.6px] border-[#E0E3E7]">
          <thead className="bg-[#E1E1E1]">
            <tr className="h-11">
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Employee Name & ID
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Check In Time & Location
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Check Out Time & Location
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Duration
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Workspace
              </th>
              <th className="pl-3 pr-2.5 py-2.5 font-[Poppins] text-[14px] text-[#1D1A22]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((items: any) => (
              <>
                <tr className="h-10 border-[0.6px] border-solid border-[#E0E3E7] hover:bg-gray-100">
                  <td className="">
                    <div className="flex items-center gap-2 pl-3 pr-2.5">
                      <img className="w-8 h-8" src={profile} alt="Profile" />
                      <div className="flex flex-col justify-between">
                        <span className="font-[Poppins] font-[500] text-[14px] leading-[21px] text-[#1D1A22]">
                          {items.User.firstName} {items.User.lastName}
                        </span>
                        <span className="text-[#605D66] font-medium text-[10px] leading-[15px] font-[Poppins]">
                          {items.User.employee_id || ""}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="hover:cursor-pointer">
                    <div className="flex flex-col justify-center gap-1 pl-4">
                      <div className="flex gap-1 justify-start items-center">
                        <img
                          src={clock}
                          alt="time"
                          className="h-[12px] w-[12px] text-[#49454F]"
                        />
                        <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
                          {dayjs(items.start_time).format("YYYY-MM-DD HH:mm")}
                        </span>
                      </div>
                      {items.check_in_address && (
                        <div className="flex gap-1 items-center">
                          <img
                            src={location}
                            alt=""
                            className="h-[12px] w-[12px] text-[#938F99]"
                          />
                          <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500] overflow-hidden whitespace-nowrap text-ellipsis w-32">
                            {items.check_in_address}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="hover:cursor-pointer">
                    <div className="flex flex-col justify-center gap-1 pl-4">
                      <div className="flex gap-1 items-center">
                        {items.end_time ? (
                          <>
                            <img
                              src={clock}
                              alt=""
                              className="h-[12px] w-[12px] text-[#49454F]"
                            />
                            <span className="text-[#1D1A22] text-[14px] font-[Poppins] font-[500]">
                              {dayjs(items?.end_time).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </span>
                          </>
                        ) : (
                          <span>--</span>
                        )}
                      </div>
                      {items.check_out_address && (
                        <div className="flex gap-1 items-center">
                          <img
                            src={location}
                            alt=""
                            className="h-[12px] w-[12px] text-[#938F99]"
                          />
                          <span className="text-[#938F99] text-[14px] font-[Poppins] font-[500] overflow-hidden whitespace-nowrap text-ellipsis w-32">
                            {items.check_out_address}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="pl-4">
                    {items.end_time ? items?.duration : "--"}
                  </td>
                  <td className="pl-4">{items?.mode_of_work}</td>
                  <td className="pl-3 pr-2.5 py-1">
                    <div className="flex items-center justify-center">
                      <img
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                        src={edit}
                        onClick={() =>
                          navigate(
                            `/attendance-management/edit-attendence/${items.id}`,
                            {
                              state: { id: items.id },
                            }
                          )
                        }
                        alt="Edit"
                      />
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      {count >= 10 && (
        <TablePaginationDemo
          count={count}
          handleItemsChange={handleNumberOfPages}
          handlePageChange={handlePageChange}
          currentPage={1}
          numOfItems={10}
        />
      )}
      <Filter
        isDate
        isOther
        heading="Attendance Filter"
        selectnameBusiness="Select Business Unit"
        optionsBusiness={bussness}
        onOptionSelectBusiness={handleBusinessUnitSelect}
        selectnameDepartment="Select Department"
        optionsDepartment={department}
        onOptionSelectDepartment={handleDepartmentUnitSelect}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onApply={handleFilterApply}
        selected={selectedDates[1]}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        isCalendarOpen={isCalendarOpen}
        handleCalanderClick={handleCalanderClick}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};

export default AttendenceDetails;
