// import React from 'react'

// const EditResources = () => {
//   return (
//     <div>EditResources</div>
//   )
// }

// export default EditResources

import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import right from "../../../image/chevron-right.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import upload from "../../../assets/plus 1.png";
import { debounce } from "lodash";
import plus from "../../../image/Mask group.svg";
import profile from "../../../assets/Ellipse 580.png";
import search from "../../../assets/Search.svg";
import {
  Button,
  Modal,
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import EmployeeSearch from "../MultiSearch";
import {
  createResourcesApi,
  Fetch_Resources_List,
} from "../../common/services/Project";
import toast from "react-hot-toast";
import del from "../../../assets/Crud_Icons/delete.svg";
import edit from "../../../assets/Crud_Icons/edit.svg";
import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import ModalResources from "../Modal";

//import CloseIcon from '@mui/icons-material/Close';

interface RowData {
  User: {
    firstName: string;
    lastName: string;
  };
  actual_cost: string;
  billing_cost: string;
  employee_type: string;
  id: string;
}

const DeleteConfirmationModal: React.FC<{
  onDelete: () => void;
  onCancel: () => void;
}> = ({ onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="mb-4 text-center text-[#1D1A22] font-[400] text-[24px] font-[Poppins]">
          Delete Resource
        </h2>
        <h4 className="mb-4 text-center font-[Poppins] text-[#49454F] font-[400] text-[20px]">
          Are you sure you want to delete this item?
        </h4>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={onDelete}
            className="buttoncss text-white px-12 py-2 rounded mr-2"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="borderButton text-black rounded px-12 py-2"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const EditResources = () => {
  const navigate = useNavigate();
  const [numOfItems, setNumOfItems] = useState(10);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
  const [page, setPage] = useState(1);
  const [projectResources, setProjectResources] = useState<
    { user_id: number; project_id: number; employee_type: string }[]
  >([]);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log(value, "val----->>>>>");

  const param = useParams();
  console.log(param.id, "param----->>>>>>>");
  const location = useLocation();
  console.log(location.state?.id, "location------>>>>>");

  const handleDeleteClick = (item: RowData) => {
    console.log(item, "delete value");
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const access_token = localStorage.getItem("access_token");
    if (selectedItem) {
      try {
        const response = await fetch(
          `${base_api}/api/v1/project-resources/${selectedItem.id}`,
          {
            method: "DELETE",
            headers: {
              "x-api-key": api_key,
              Authorization: "Bearer " + access_token,
            },
          }
        );
        if (response.ok) {
          setTableData(
            tableData?.filter((item) => item.id !== selectedItem.id)
          );
        } else {
          console.error("Failed to delete the item");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setShowModal(false);
        setSelectedItem(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleEmployeeSelect = (employeeIds: string[]) => {
    console.log("Selected Employee IDs:", employeeIds);
  };

  const handleEmployeeSelectId = (userIds: string[]) => {
    console.log("Selected User IDs:", userIds);

    if (!param.id) {
      console.error("param.id is undefined");
      return;
    }

    const projectId = parseInt(param.id);
    if (isNaN(projectId)) {
      console.error("param.id is not a valid number");
      return;
    }

    const newResources = userIds.map((id) => ({
      user_id: parseInt(id),
      project_id: projectId, // Example project_id, replace with your actual project_id
      employee_type: value === 0 ? "Manager" : "Employee",
    }));
    setProjectResources([...newResources]);
  };

  const handleSave = () => {
    postData();
  };

  const postData = async () => {
    // setLoading(true);
    try {
      const response = await createResourcesApi(
        // customer_id,
        // uploadedFiles.map((files) => files?.originalName.split(".")),
        projectResources
      );
      console.log(response.data.result, "respost--->>>");
      const projectId = response.data.result[0].project_id;

      setOpen(true);

      setTimeout(() => {
        setOpen(false);
        //navigate(`${ROLE_MANAGEMENT}`);
        navigate(`/edit-task-details/${projectId}`, {
          state: { id: projectId },
        });
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    //  setLoading(false);
  };

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await Fetch_Resources_List(
        param.id,
        searchTerm,
        numOfItems,
        page
        // startDate,
        // endDate
      );
      if (response.status === 200) {
        console.log("Data: Resources employee", response.data.result);
        setTableData(response.data.result.rows);
        // setCount(response.data.result.count);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
    console.log("after fetch page", page);
  }, [page, numOfItems, searchTerm]);

  console.log(projectResources, "projectres----->>>>>>>>>");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(
    null
  );

  const handleEditClick = (id: string) => {
    setSelectedEmployeeId(id);
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
    fetchData();
  };

  const updateSearchTerm = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      // fetchData(event.target.value); // call API to fetch data
    }, 100), // delay in ms
    []
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: "none",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Resource
              </Typography>
              <IconButton onClick={handleClose}>
                {/* <CloseIcon /> */} X
              </IconButton>
            </Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Manager" />
              <Tab label="Employee" />
            </Tabs>

            <Box display="flex" justifyContent="space-between" mt={2}>
              <EmployeeSearch
                onEmployeeSelect={handleEmployeeSelect}
                onEmployeeSelectId={handleEmployeeSelectId}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  bgcolor: "linear-gradient(to right, #6a11cb, #2575fc)",
                  height: "40px",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </>
      </Modal>

      <div className="py-6">
        <div className="flex flex-row items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button
              onClick={() =>
                navigate(`/edit-project-details`, {
                  state: { id: param.id },
                })
              }
            >
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Edit Resources
            </span>
          </div>
        </div>

        <div className="flex justify-between mt-8">
          <div className="w-[30%] flex justify-between items-center flex-[1,0,0] rounded-md border-[1px] border-solid border-[#CACACA] bg-white">
            <input
              type="text"
              placeholder="Search"
              className="text-[#3F4254] w-[95%] outline-none rounded-md py-2 pl-2.5 font-[Poppins] text-[13px] font-normal leding-normal"
              onChange={updateSearchTerm}
            />
            <img
              src={search}
              alt="search sign"
              className="h-[19px] w-[19px] shrink-0"
            />
          </div>
          <button
            onClick={() => setOpen(true)}
            className="flex items-center font-[600] text-[14px] py-2 px-4 buttoncss text-white rounded-lg"
          >
            <img className="w-4 h-4 mr-2" src={plus} alt="plus icon" />
            Add Resources
          </button>
        </div>

        <div className="overflow-x-auto mt-5">
          <table className="w-full min-w-max text-left table-auto  border-[0.6px] border-[#EBEBEB]">
            <thead className="bg-[#E1E1E1]">
              <tr className="h-11 px-8">
                <th className="pl-8 pr-2.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Resources
                </th>
                <th className="pl-6 text-center pr-0.5 py-2.5 text-[14px] text-[#1D1A22]">
                  Billable Cost/hr (Dollar)
                </th>
                <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-center text-[#1D1A22]">
                  Actual Cost/hr (Dollar)
                </th>
                <th className="pl-6 pr-2.5 py-2.5 text-[14px] text-[#1D1A22] text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((items) => (
                <tr className="h-10 border-[0.6px] px-8 border-solid border-[#E0E3E7] hover:cursor-pointer">
                  <td className="relative pl-8 pr-2.5 py-1">
                    {items?.employee_type === "Manager" && (
                      <div className="flex h-full items-center absolute left-0 top-0">
                        <div className="h-8 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none w-[22px] bg-[#EAE0FF] flex items-center justify-center">
                          <span className="font-[Poppins] font-semibold text-[12px] leading-[18px] text-[#1D1A22]">
                            M
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="h-[40px] flex items-center gap-2.5">
                      <div className="h-full w-[40px] rounded-full">
                        <img
                          src={profile}
                          alt="profile"
                          className="h-full w-full"
                        />
                      </div>
                      <div className="h-full flex flex-col justify-center">
                        <span className="font-[Poppins] font-medium text-[14px] leading-[21px] text-[#1D1A22]">
                          {items?.User.firstName} {items?.User?.lastName}
                        </span>
                        <span className="font-[Poppins] font-medium text-[10px] leading-[15px] text-[#605D66]">
                          {" "}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-[#3F4254] text-center font-[500] text-[12px] pl-6 py-1">
                    {items?.actual_cost || "--"}
                  </td>
                  <td className="text-[#3F4254] font-[500] text-center text-[12px] pl-6 pr-2.5 py-1">
                    {items?.billing_cost || "--"}
                  </td>
                  <td className="pl-6 pr-2.5 py-1">
                    <div className="w-full h-full flex justify-center items-center gap-2.5">
                      <img
                        src={edit}
                        onClick={() => handleEditClick(items.id)}
                        alt="Edit"
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                      <img
                        src={del}
                        onClick={() => handleDeleteClick(items)}
                        alt="Edit"
                        className="cursor-pointer p-0.5 hover:bg-[#E6E6E6] rounded-md"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {showModal && (
            <DeleteConfirmationModal
              onDelete={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}

          {selectedEmployeeId && (
            <ModalResources
              isOpen={isModalOpen}
              onClose={onClose}
              employeeId={selectedEmployeeId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditResources;
