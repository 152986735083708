import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import right from "../../image/chevron-right.png";
import check from "../../image/checkmark-circle-02.svg";
import { TextField, Box, Modal, Typography, MenuItem } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { api_key_work as api_key, base_api } from "../../utils/helperData";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Fetch_Industry_Vertical_Data_Api,
  Fetch_Industry_Vertical_Data_Api_Login,
} from "../common/services/onBoarding";
import dayjs, { Dayjs } from "dayjs";
import {
  Fetch_Company_Details_List,
  updateCompanyDetails,
} from "../common/services/companyManagement";
import { useSelector } from "react-redux";
import FileUpload from "../ExpenseManagement/EditFileUoload";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

interface FormValues {
  organisationName: string;
  industryVertical: string;
  organisationEmail: string;
  businessSize: string;
  website: string;
  postalCode: string;
  organisationAddress: string;
  city: string;
}

interface IndustryItem {
  id: number;
  name: string;
}

interface Country {
  id: number;
  country_name: string;
}

interface State {
  id: number;
  state_name: string;
}
interface UploadedFile {
  originalName: string;
  url: string;
  fileType: string;
  original: string;
}

export const EditCompanyDetails = () => {
  const loginData = useSelector((state: any) => state.auth.login.login_details);

  console.log(loginData, "loginDataloginData---->>>>");

  const navigate = useNavigate();
  let signup_token: string = "";

  const [industryVerticalData, setIndustryVerticalData] = useState<
    IndustryItem[]
  >([]);
  const [joining, setJoining] = React.useState<Dayjs | string | null>(null);
  const [numOfItems, setNumOfItems] = useState(10);
  const [page, setPage] = useState(1);
  const [companyId, setCompanyId] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryData, setCountryData] = useState<Country[]>([]);
  const [countryId, setCountryId] = useState("0");
  const [stateData, setStateData] = useState<State[]>([]);
  const [stateId, setStateId] = useState("");
  const [enable, setEnable] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<UploadedFile[]>([]);

  const ModeOfBusiness = [
    { value: "0-10", label: "0-10" },
    { value: "10-50", label: "10-50" },
    { value: "50-100", label: "50-100" },
    { value: "100-500", label: "100-500" },
  ];

  const [formData, setFormData] = useState({
    name: "",
    //email: signupData.sign_up_details.email,
    //business_size: "default",
    industry_vertical: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });

  const [formValues, setFormValues] = useState<FormValues>({
    organisationName: "",
    industryVertical: "",
    organisationEmail: "",
    businessSize: "",
    website: "",
    postalCode: "",
    organisationAddress: "",
    city: "",
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    submitChanges();
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/company-management");
  };

  const fetchLeaveType = async () => {
    setLoading(true);
    try {
      const response = await Fetch_Company_Details_List(numOfItems, page);

      if (response.status === 200) {
        console.log(
          "Data: company management Tpye",
          response.data.result?.logo
        );
        //  setTableData(response?.data?.result);
        //setCompanyDetails(response.data.result);

        setFormValues({
          organisationName: response.data?.result?.name,
          industryVertical: response.data?.result?.industry_vertical,
          organisationEmail: response.data?.result?.email,
          businessSize: response.data?.result?.business_size,
          website: response.data?.result?.website,
          postalCode: response.data?.result?.postal_code,
          organisationAddress: response.data?.result?.address,
          city: response.data?.result?.city,
        });
        setCountryId(response.data?.result?.country);
        setStateId(response.data?.result?.state);
        setJoining(dayjs(response.data?.result?.start_date));
        setCompanyId(response.data?.result?.id);

        const { receipt_file_type, receipt_filename, receipt_name, logo } =
          response?.data?.result;

        const files: UploadedFile[] =
          Array.isArray(receipt_filename) && receipt_filename.length > 0
            ? receipt_filename.map((name: string, index: number) => ({
                originalName: receipt_name || "Unknown", // Default if receipt_name is missing
                url: logo, // Access the corresponding file URL
                fileType: receipt_file_type
                  ? receipt_file_type[index]
                  : "unknown",
                original: "", // Default if receipt_file_type is missing
              }))
            : [];

        if (logo) {
          files.push({
            //  originalName: "Company Logo", // Or any name you want to assign
            url: logo,
            originalName: logo,
            fileType: "",
            original: "",
          });
        }

        if (files.length > 0) {
          setInitialFiles(files);
          setUploadedFiles(files);
        } else {
          console.warn("No files available to set as initial files.");
        }
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  console.log(stateId, "stateIdstateId------>>>>>");
  console.log(countryId, "countryIdcountryId------>>>>>");

  useEffect(() => {
    fetchLeaveType();
  }, [page, numOfItems]);

  console.log(companyId, "companyIdcompanyId----->>>>>");

  const submitChanges = async () => {
    // const resourceChanges: { resource_id: number; type: string }[] = [];

    setLoading(true);
    try {
      const data = await updateCompanyDetails(
        companyId,
        formValues.organisationName,
        formValues.organisationEmail,
        formValues.organisationAddress,
        formValues.businessSize,
        joining,
        Number(formValues.industryVertical),
        formValues.city,
        stateId,
        formValues.postalCode,
        String(companyId),
        formValues.website,
        uploadedFiles[0].url
        //"https://dev-go-employee.s3.ap-south-1.amazonaws.com/uploads/1721121143371-221574843.png"
      );

      if (data && data.result === "") {
        console.log("final data:", data);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          //navigate(`${ROLE_MANAGEMENT}`);
          navigate("/company-management");
        }, 1000);
        // navigate(`/designation-management`, { state: { refresh: true } });
      } else {
        console.error("Data or result is null");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error", error);
        toast.error("An error occurred, could not update");
      }
    }
    setLoading(false);
  };

  let access_token = "";

  if (loginData !== null) {
    access_token = loginData.token || loginData.access_token || "";
    console.log(loginData.token, "loginData.customer_id--->>>>");
  }

  // const access_token = localStorage.getItem("access_token");

  console.log(uploadedFiles[0], "up------>>>>");
  console.log(initialFiles[0], "in------>>>>");

  useEffect(() => {
    const getCountry = async () => {
      const reqdata = await fetch(
        `${base_api}/api/v1/country?page=1&size=264`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: access_token,
          },
        }
      );
      const resdata = await reqdata.json();
      const validData = resdata.result.rows;
      setCountryData(validData);
    };
    getCountry();
  }, [access_token]);

  const handleState = (e: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateData[Number(e.target.value)]?.state_name,
    }));
    console.log();

    setStateId(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Fetch_Industry_Vertical_Data_Api_Login();
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setIndustryVerticalData(data.result);
      } catch (error) {
        console.error("Error fetching industry vertical data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCountry = async (e: any) => {
    console.log(e.target.value, "e.target.value----->>>>>");

    setCountryId(e.target.value);

    if (e.target.value) {
      const reqStateData = await fetch(
        `${base_api}/api/v1/state?country_id=${e.target.value}&page=1&size=100`,
        {
          method: "GET",
          headers: {
            "x-api-key": api_key,
            Authorization: access_token,
          },
        }
      );
      const resStateData = await reqStateData.json();
      const validData: [] = resStateData.result.rows;
      setStateData(validData);
      // setEnable(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: countryData[Number(e.target.value) - 1]?.country_name,
      }));
    } else {
      setStateData([]);
      // setEnable(true);
    }
  };

  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
    console.log("Files updated:", files);
  };

  useEffect(() => {
    const stateDataValue = async () => {
      if (countryId) {
        const reqStateData = await fetch(
          `${base_api}/api/v1/state?country_id=${countryId}&page=1&size=100`,
          {
            method: "GET",
            headers: {
              "x-api-key": api_key,
              Authorization: access_token,
            },
          }
        );
        const resStateData = await reqStateData.json();
        const validData: [] = resStateData.result.rows;
        setStateData(validData);
        // setEnable(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          country: countryData[Number(companyId) - 1]?.country_name,
        }));
      } else {
        setStateData([]);
        // setEnable(true);
      }
    };

    stateDataValue();
  }, [countryId]);

  return (
    <div className="px-4 py-6">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img style={{ margin: "auto" }} src={check} alt="" />
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            Company details updated successfully!
          </Typography>
        </Box>
      </Modal>
      <div className="w-full flex flex-row justify-between">
        <div className="w-full flex flex-row px-4 items-center h-12 justify-between">
          <div className="flex flex-row gap-[13px] justify-center items-center">
            <button onClick={() => navigate("/company-management")}>
              <img
                src={right}
                alt="right arrow sign"
                className="h-6 w-6 shrink-0 bg-black rounded-full"
              />
            </button>
            <span className="text-black font-[Poppins] text-xl font-semibold leading-normal">
              Company Details
            </span>
          </div>
          <div className="flex flex-start gap-5">
            <button
              onClick={() => navigate("/company-management")}
              className="flex w-[148px] p-[13.18px,50px,14.82px,51px] justify-center items-center self-stretch rounded-lg border border-solid border-[#242424] h-12"
            >
              <div className="text-[#2C2C2C] text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Cancel
              </div>
            </button>
            <button
              type="submit"
              form="form"
              onClick={handleOpen}
              className="flex w-[138px] p-[13.18px,21px,14.82px,21px] justify-center items-center flex-[1,1,0] self-stretch rounded-lg  buttoncss text-white h-12"
            >
              <div className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize">
                Save
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="p-6 shadow-lg">
        <div className="flex flex-col gap-4 rounded-lg">
          <div className="flex flex-col gap-2">
            <span className="p-1 font-['Poppins'] font-medium text-base text-[#262626]">
              Company Details
            </span>
            <div className="grid">
              <div className="rounded overflow-hidden bg-white">
                <div className="container p-4">
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="grid grid-cols-3 gap-4">
                      <TextField
                        required
                        id="outlined-required"
                        label="Organisation's Name"
                        name="organisationName"
                        value={formValues.organisationName}
                      />
                      <TextField
                        required
                        id="outlined-required"
                        label="Organisation's Email"
                        name="organisationEmail"
                        value={formValues.organisationEmail}
                      />
                      <TextField
                        required
                        id="outlined-required"
                        label="Business Size"
                        select
                        name="businessSize"
                        value={formValues.businessSize}
                      >
                        {ModeOfBusiness.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        id="outlined-required"
                        label="Indutry Vertical"
                        select
                        name="industryVertical"
                        value={formValues.industryVertical}
                      >
                        {industryVerticalData.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        id="outlined-required"
                        label="Country"
                        name="country"
                        select
                        value={countryId}
                        onChange={(e) => handleCountry(e)}
                      >
                        {countryData?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.country_name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        id="outlined-required"
                        label="State"
                        name="state"
                        select
                        value={stateId}
                        onChange={handleState}
                        // disabled={enable}
                      >
                        {stateData?.map((option) => (
                          <MenuItem
                            key={option.state_name}
                            value={option.state_name}
                          >
                            {option?.state_name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        id="outlined-required"
                        label="City"
                        name="city"
                        value={formValues.city}
                      />
                      <TextField
                        required
                        id="outlined-required"
                        label="Organisation's Address"
                        name="organisationAddress"
                        value={formValues.organisationAddress}
                      />
                      <TextField
                        required
                        id="outlined-required"
                        label="Postal Code"
                        name="postalCode"
                        value={formValues.postalCode}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Started On"
                          value={joining}
                          onChange={(newValue) => setJoining(newValue)}
                        />
                      </LocalizationProvider>

                      <TextField
                        required
                        id="outlined-required"
                        label="Website"
                        name="website"
                        value={formValues.website}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="p-1 font-['Poppins'] font-medium text-base text-[#262626]">
              Additional Information
            </span>
            <div className="flex flex-col gap-1">
              <span className="p-1 font-['Poppins'] font-medium text-sm text-[#262626]">
                Organization Logo
                <br />
                <FileUpload
                  existingFiles={initialFiles}
                  onFilesChange={handleFilesChange}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
