import howToUse from "../../assets/images/howToUse.png";
import settings from "../../assets/images/settings.png";
import Search from "../../assets/Search.svg";
import "./sidebar.css";
import logo from "../../assets/GT-logo.svg";
import arrowDoubleRight from "../../assets/arrow-double-right.svg";

// ! INTERFACES

interface SidebarData {
  resource_id: number;
  resource_name: string;
  parent_id: number;
  icon: string;
  children: [];
}

const SideBarCollapsed: React.FC<{
  data: SidebarData[] | undefined;
  setIsCollapsed: (value: boolean) => void;
  setIsHovered: (value: boolean) => void;
}> = (props) => {
  //! STATES

  //! FUNCTIONS}

  //! useEffect()

  return (
    <div
      className="flex-col bg-[#192440] h-full max-w-[99px] pl-[30px] pr-[38px] pt-5 pb-[272px] bg-slate-800 justify-start items-center gap-[37px] inline-flex overflow-y-auto scrollbar-hide"
      onMouseEnter={() => props.setIsHovered(true)}
      onMouseLeave={() => props.setIsHovered(false)}
    >
      <div className="flex flex-row gap-2 items-center">
        <img
          src={logo}
          alt="company-logo"
          className="w-7 h-[30.37px] relative"
        />

        <img
          src={arrowDoubleRight}
          alt="company-logo"
          className="w-6 h-6 origin-right justify-start items-start gap-2.5 inline-flex cursor-pointer"
          onClick={() => props.setIsCollapsed(true)}
        />
      </div>

      <img src={Search} className="w-7 h-7" alt="img" />

      {/* Rendering Data for Side menu */}
      {props.data?.map((item) => {
        return (
          <img
            src={item.icon}
            className="w-6 h-6 mt-1 aspect-square"
            alt="img"
          />
        );
      })}
      <img src={settings} className="w-6 h-6" alt="img" />
    </div>
  );
};

export default SideBarCollapsed;
