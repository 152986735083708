import { api_key_work as api_key, base_api } from "../../../utils/helperData";
import axios from "axios";
import { FETCH_EMPLOYEE_EXPENSE_TYPE } from "../api";
import { Dayjs } from "dayjs";
import moment from "moment"

export const Fetch_Employee_Expense_List = (
  customer_id: number,
  searchTerm: string,
  numOfItems: number,
  page: number,
  group_id: number,
  department_id: number,
  startDate: Date | null,
  endDate: Date | null
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}&size=${numOfItems}&page=${page}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  if (searchTerm !== "") {
    query += `&search=${searchTerm}`;
  }

  if (group_id) {
    query += `&group_id=${group_id}`;
  }

  if (department_id) {
    query += `&department_id=${department_id}`;
  }

  if (startDate && endDate) {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    query += `&start_date=${start}&end_date=${end}`;
  }

  return axios.get(`${FETCH_EMPLOYEE_EXPENSE_TYPE}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const Fetch_Employee_Expense_List1 = (
  customer_id: number,
  
) => {
  const access_token = localStorage.getItem("access_token");
  // let query = `?size=${numOfItems}&page=${page}`;
  let query = `?customer_id=${customer_id}`;
  //&size=${numOfItems}&page=${page}

  // if (sortType != undefined) {
  //   query += `&sort_by=${sortType.key}&sort_order=${sortType.order}`;
  // }

  return axios.get(`${FETCH_EMPLOYEE_EXPENSE_TYPE}${query}`, {
    headers: {
      "x-api-key": api_key,
      Authorization: "Bearer " + access_token,
    },
  });
};

export const createEmployeeExpense = async (
  //customerId: number,
  user_id: string | null,
  category_id: string,
  expense_date: string | null | Dayjs,
  expense_amount: string,
  expense_currency: string,
  receipt_name: string[],
  receipt_filename: string[],
  // receipt_file_type:string[],
  description: string
) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    //customerId: customerId,
    user_id: user_id,
    category_id: category_id,
    expense_date: expense_date,
    expense_amount: expense_amount,
    expense_currency: expense_currency,
    receipt_name: receipt_name,
    receipt_filename: receipt_filename,
    //receipt_file_type:receipt_file_type,
    description: description,
  };

  try {
    const response = await axios.post(`${base_api}/api/v1/expenses`, data, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchEditEmployeeExpense = async (id: string) => {
  const access_token = localStorage.getItem("access_token");
  console.log(id, "id-----api----check");
  try {
    const response = await axios.get(`${base_api}/api/v1/expenses/${id}`, {
      headers: {
        "x-api-key": api_key,
        Authorization: "Bearer " + access_token,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateEmployeeExpense = async (
  id: string,
  category_id: string,
  expense_date: string | null | Dayjs,
  expense_amount: string,
  expense_currency: string,
  //receipt_name:string[],
  receipt_filename: string[],
  // receipt_file_type:string[],
  description: string
) => {
  const access_token = localStorage.getItem("access_token");
  const body = {
    category_id: category_id,
    expense_date: expense_date,
    expense_amount: expense_amount,
    expense_currency: expense_currency,
    // receipt_name:receipt_name,
    receipt_filename: receipt_filename,
    // receipt_file_type:receipt_file_type,
    description: description,
  };

  try {
    const response = await axios.patch(
      `${base_api}/api/v1/expenses/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const approveRejectApi = async (id: number, status: string) => {
  const access_token = localStorage.getItem("access_token");
  const data = {
    status: status,
  };

  try {
    const response = await axios.post(
      `${base_api}/api/v1/expenses/approve-reject/${id}`,
      data,
      {
        headers: {
          "x-api-key": api_key,
          Authorization: "Bearer " + access_token,
        },
      }
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
